import { Chart, LoadPanel } from "devextreme-react";
import { Legend, Font, Size } from "devextreme-react/bar-gauge";
import {
  CommonSeriesSettings,
  ArgumentAxis,
  Series,
  ValueAxis,
  Grid,
  ZoomAndPan,
  ScrollBar,
  Crosshair,
  Point,
  Tooltip,
} from "devextreme-react/chart";
import { Label } from "devextreme-react/form";
import moment from "moment";
import TooltipTemplate from "./TooltipTemplate";
import React from "react";
import { markerTemplate } from "../../utils/markerTemplates";

const BpkdChart = React.memo(
  ({
    transformedData,
    selectedDates,
    offestedDataVisible,
    exportFileDataName,
    title,
    variableName,
  }: {
    transformedData: any;
    selectedDates: any;
    offestedDataVisible: boolean;
    exportFileDataName: string;
    title: string;
    variableName: string;
  }) => {
    const onPointHoverChanged = (e: any): void => {
      if (!e.target.isHovered()) {
        e.target.hideTooltip();
      }
    };

    const firstColors = ["#003f5c", "#955196", "#ff6e547f"];
    const secondColors = ["#444e86", "#dd5182", "#ffa6007f"];
    return (
      <>
        <Chart
          id={variableName}
          className="bpkd-chart"
          onPointHoverChanged={onPointHoverChanged}
          palette="Harmony Light"
          dataSource={transformedData}
          title={title}
          export={{
            enabled: true,
            fileName: `${exportFileDataName}_${variableName}`,
          }}
        >
          <CommonSeriesSettings type={`spline`} argumentField="Data">
            <Point visible={false} size={3} symbol="circle"></Point>
          </CommonSeriesSettings>
          <Legend
            columnCount={3}
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="bottom"
            markerRender={markerTemplate}
          />
          <ArgumentAxis
            valueMarginsEnabled={true}
            argumentType="datetime"
            label={{
              format: "dd/MM HH:mm",
              timezone: "",
            }}
            tick={{
              visible: true,
              length: 6,
              shift: 0,
              color: "#000",
              opacity: 1,
              width: 1,
            }}
            minorTick={{
              visible: true,
              length: 3,
              shift: 0,
              color: "#000",
              opacity: 1,
              width: 1,
            }}
          >
            <Grid visible={true} />
          </ArgumentAxis>
          <Series
            axis="generatedValue"
            type="spline"
            valueField={`newest_${variableName}`}
            name={`BPKD Generacja ${moment(selectedDates.fromDate).format(
              "DD-MM-YYYY"
            )} - ${moment(selectedDates.toDate)
              .subtract(1, "day")
              .format("DD-MM-YYYY")}`}
            color={firstColors[0]}
            argumentField="Data"
          />
          <Series
            axis="generatedValue"
            type="spline"
            valueField={`first_${variableName}`}
            name={`PKD Generacja ${moment(selectedDates.fromDate).format(
              "DD-MM-YYYY"
            )} - ${moment(selectedDates.toDate)
              .subtract(1, "day")
              .format("DD-MM-YYYY")}`}
            color={firstColors[1]}
            argumentField="Data"
          />
          <Series
            axis="deltaValue"
            type="stackedbar"
            valueField={`delta_${variableName}`}
            name={`&#916; BPKD-PKD  ${moment(selectedDates.fromDate).format(
              "DD-MM-YYYY"
            )} - ${moment(selectedDates.toDate)
              .subtract(1, "day")
              .format("DD-MM-YYYY")}`}
            color={firstColors[2]}
            opacity={0}
            argumentField="Data"
          />
          {offestedDataVisible && (
            <Series
              axis="generatedValue"
              type="spline"
              valueField={`offset_newest_${variableName}`}
              name={`BPKD Generacja ${moment(
                selectedDates.offsetedFromDate
              ).format("DD-MM-YYYY")} - ${moment(selectedDates.offsetedToDate)
                .subtract(1, "day")
                .format("DD-MM-YYYY")}`}
              color={secondColors[0]}
              argumentField="Data"
            />
          )}
          {offestedDataVisible && (
            <Series
              axis="generatedValue"
              type="spline"
              valueField={`offset_first_${variableName}`}
              name={`PKD Generacja ${moment(
                selectedDates.offsetedFromDate
              ).format("DD-MM-YYYY")} - ${moment(selectedDates.offsetedToDate)
                .subtract(1, "day")
                .format("DD-MM-YYYY")}`}
              color={secondColors[1]}
              argumentField="Data"
            />
          )}
          {offestedDataVisible && (
            <Series
              axis="deltaValue"
              type="stackedbar"
              valueField={`delta_offset_${variableName}`}
              name={`&#916; BPKD-PKD ${moment(
                selectedDates.offsetedFromDate
              ).format("DD-MM-YYYY")} - ${moment(selectedDates.offsetedToDate)
                .subtract(1, "day")
                .format("DD-MM-YYYY")}`}
              color={secondColors[2]}
              argumentField="Data"
            />
          )}
          <ValueAxis name="generatedValue" position="left" title="MWh">
            <Grid visible={true} />
          </ValueAxis>
          <ValueAxis name="deltaValue" position="right" title="&#916; MWh">
            <Grid visible={true} />
          </ValueAxis>
          <ZoomAndPan argumentAxis="both" />
          <ScrollBar visible={true} />
          <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
            <Label visible={true}>
              <Font color="#fff" size={12} />
            </Label>
          </Crosshair>
          <Size height={500} />
          <Tooltip
            border={{ color: "#d3d3d3", width: 1 }}
            shadow={{ color: "#888", opacity: 0.5 }}
            interactive={true}
            arrowLength={10}
            enabled={true}
            shared={true}
            cornerRadius={5}
            contentRender={(e) => TooltipTemplate(e, variableName)}
          />
        </Chart>
      </>
    );
  }
);

export default BpkdChart;
