import React, { createContext, ReactNode, useContext, useState } from "react";
import { Toast as DevExtremeToast } from "devextreme-react/toast";
import { ToastType } from "devextreme/ui/toast";
import { ToastContextData, ToastProps, ToastProviderProps } from "../types";

const ToastContext = createContext<ToastContextData>({
  showMessage: () => {},
});
const Toast: React.FC<ToastProps> = ({
  visible,
  message,
  type,
  onHiding,
  displayTime = 2000,
}) => {
  return (
    <DevExtremeToast
      visible={visible}
      message={message}
      type={type}
      onHiding={onHiding}
      displayTime={displayTime}
    />
  );
};

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "info",
    message: "",
  });

  const showMessage = (message: string, type: ToastType) => {
    setToastConfig({
      isVisible: true,
      type: type,
      message,
    });
  };

  const hideMessage = () => {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  };

  return (
    <ToastContext.Provider value={{ showMessage }}>
      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type as ToastType}
        onHiding={hideMessage}
      />
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);

export { ToastProvider, useToast };
