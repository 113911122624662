import React, { useEffect, useRef, useState, useCallback } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Export,
  HeaderFilter,
  Pager,
  Format,
  Paging,
  Scrolling,
} from "devextreme-react/data-grid";
import { Chart, LoadPanel } from "devextreme-react";
import { Label } from "devextreme-react/form";
import { api } from "../../api/api";
import { Font, Size, Title } from "devextreme-react/bar-gauge";
import {
  ArgumentAxis,
  CommonAxisSettings,
  ConstantLine,
  Legend,
  MinorGrid,
  MinorTick,
  Tick,
} from "devextreme-react/chart";
import {
  AdaptiveLayout,
  CommonSeriesSettings,
  Grid,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Series,
  ValueAxis,
  Point,
  Tooltip,
  Aggregation,
} from "devextreme-react/chart";

import { setSelectedDates as setToday } from "../../utils/DateSelectors";
import {
  TransformedStockData,
  ReturnDateObject,
  PlPwmRdnTypes,
  PlPwmRdnData,
  SummedPlPwmRdnData,
} from "../../types";

import { onExportingDataGrid } from "../../utils/onExportingCsvFile";

import notify from "devextreme/ui/notify";

import DateOneDaySelector from "../../components/date-oneDay-selector/DateOneDaySelector";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import { markerTemplate } from "../../utils/markerTemplates";
import moment from "moment";
import TooltipTemplate from "./TooltipTemplate";
import { getStrokePattern } from "../../utils/chartPatternGenerator";
import { notifyMessage } from "../../utils/constants";

// PlPwmRdnData
const PlPwmRdn = () => {
  const displayedDateFormat = "DD-MM-YYYY";
  const position = { of: "#chart" };
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [selectedDates, setSelectedDates] = useState<{
    fromDate: string;
    toDate: string;
    offsetedFromDate?: string;
    offsetedToDate?: string;
  }>({
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    offsetedFromDate: moment().format("YYYY-MM-DD"),
    offsetedToDate: moment().format("YYYY-MM-DD"),
  });
  const [offestedDataVisible, setOffestedDataVisible] = useState(false);
  const stockUrlEndpoint = "pwm-rdn";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[]
  >([]);
  const pagetitle = "Wymiana międzysystemowa RDN";
  const changeOffsetedObjArrnames = (arr: Array<any>) => {
    const newArray = arr.map((obj) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, val]) =>
          key === "Godzina" ? [key, val] : [`${key}_off`, val]
        )
      )
    );
    return newArray;
  };

  const addSumProperties = (array: PlPwmRdnData[]): SummedPlPwmRdnData[] => {
    return array.map((obj: SummedPlPwmRdnData) => {
      let total_sum = 0;
      const newObj = { ...obj };
      Object.entries(obj).forEach(([key, val]) => {
        if (key !== "Godzina" && key !== "Data") {
          total_sum += val as number;
        }
      });
      newObj.total_sum = Math.round((total_sum + Number.EPSILON) * 100) / 100;

      return newObj;
    });
  };

  const getData = async (
    fromDate: string,
    toDate: string,
    offset?: boolean
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);

    const filter = {
      limit: 1,
      order: "string",
      where: {
        data_cet: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      setTransformedData([]);
      const { data } = await api.get<PlPwmRdnTypes[]>(stockUrlEndpoint, {
        params: { filter },
      });
      if (data?.length) {
        console.log(data);
        setIsLoadingVisible(false);
        const summedData = addSumProperties(data[0].dane);
        console.log(summedData);
        return !!offset ? changeOffsetedObjArrnames(summedData) : summedData;
      } else {
        setIsLoadingVisible(false);
        notify(
          notifyMessage + moment(fromDate).format(displayedDateFormat),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getStockData = async (
    selectedDates: ReturnDateObject
  ): Promise<void> => {
    setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );

    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format("YYYY-MM-DD"),
        toDate: moment(selectedDates.toDate).format("YYYY-MM-DD"),
        offsetedFromDate: moment(selectedDates.offsetedFromDate).format(
          "YYYY-MM-DD"
        ),
        offsetedToDate: moment(selectedDates.offsetedToDate).format(
          "YYYY-MM-DD"
        ),
      });
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          true
        ),
      ])
        .then(([data, offsetedData]) => {
          if (data !== undefined && offsetedData !== undefined) {
            setOffestedDataVisible(true);
            const concatedArr = [...data, ...offsetedData].reduce(
              (acc, curr) => {
                const findingIndex = acc.findIndex(
                  (el: { Godzina: any }) => el.Godzina === curr.Godzina
                );
                if (findingIndex !== -1) {
                  acc[findingIndex] = {
                    ...acc[findingIndex],
                    ...curr,
                  };
                } else {
                  acc.push({ ...curr });
                }
                return acc;
              },
              []
            );
            console.log(concatedArr);
            setTransformedData(concatedArr as any[]);
          } else if (data !== undefined && offsetedData === undefined) {
            setTransformedData(data as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format("YYYY-MM-DD"),
        toDate: moment(selectedDates.toDate).format("YYYY-MM-DD"),
      });
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      console.log(data);
      setTransformedData(data as any[]);
    }
  };
  useEffect(() => {
    const data = setToday({
      selectedFromDate: new Date(),
    });
    getStockData(data);
  }, []);

  const onPointHoverChanged = (e: any): void => {
    if (!e.target.isHovered()) {
      e.target.hideTooltip();
    }
  };
  const cellPrepared = (e: any) => {
    if (e.rowType === "data") {
      if (e.column.dataField === "Godzina") {
        e.cellElement.style.cssText = "background-color: #f5f5f5";
      }
    }
  };

  const countryColorsOff = [
    "#54bf8f",
    "#45ae78",
    "#389e62",
    "#2b8e4c",
    "#1e7e35",
    "#106e1e",
    "#005e00",
  ];
  const countryColors = [
    "#56babf",
    "#3eacbb",
    "#259db6",
    "#048fb1",
    "#0080aa",
    "#0071a2",
    "#006298",
  ];

  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>{pagetitle}</h2>
      <DateOneDaySelector onClick={(dateObj) => getStockData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Chart
            dataSource={transformedData}
            palette="Harmony Light"
            onPointHoverChanged={onPointHoverChanged}
            // onLegendClick={onLegendClick}

            export={{
              enabled: true,
              fileName: `${exportFileDataName}_${pagetitle}`,
            }}
          >
            <CommonSeriesSettings type={"stackedbar"} argumentField="Godzina">
              <Point visible={false}></Point>
            </CommonSeriesSettings>
            <ArgumentAxis valueMarginsEnabled={true}>
              <Grid visible={true} />
            </ArgumentAxis>
            <ValueAxis position="left" name="values" valueType="numeric">
              <ConstantLine
                width={1}
                value={0}
                color="#000"
                dashStyle="solid"
                displayBehindSeries={false}
              >
                <Label text="" />
              </ConstantLine>
              <Title text="MW" />
            </ValueAxis>

            <Series
              hoverMode="nearestPoint"
              valueField="Czechy_EXP"
              name={`Czechy export ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              opacity={0.5}
              color={countryColors[0]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Czechy_EXP_off"
                name={`Czechy export ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[0]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Czechy_IMP"
              name={`Czechy import ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[0]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Czechy_IMP_off"
                name={`Czechy import ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[0]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Slowacja_EXP"
              name={`Słowacja export ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[1]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Slowacja_EXP_off"
                name={`Słowacja export ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[1]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Slowacja_IMP"
              name={`Słowacja import ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[1]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Slowacja_IMP_off"
                name={`Słowacja import ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[1]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Niemcy_EXP"
              name={`Niemcy export ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[2]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Niemcy_EXP_off"
                name={`Niemcy export ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[2]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Niemcy_IMP"
              name={`Niemcy import ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[2]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Niemcy_IMP_off"
                name={`Niemcy import ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[2]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Szwecja_EXP"
              name={`Szwecja export ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[3]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Szwecja_EXP_off"
                name={`Szwecja export ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[3]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Szwecja_IMP"
              name={`Szwecja import ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[3]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Szwecja_IMP_off"
                name={`Szwecja import ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[3]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Litwa_EXP"
              name={`Litwa export ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[4]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Litwa_EXP_off"
                name={`Litwa export ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[4]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Litwa_IMP"
              name={`Litwa import ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[4]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Litwa_IMP_off"
                name={`Litwa import ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[4]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Ukraina_ZAM_DOB_EXP"
              name={`Ukraina eksport (ZAM-DOB) ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[5]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Ukraina_ZAM_DOB_EXP_off"
                name={`Ukraina eksport (ZAM-DOB) ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[5]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Ukraina_ZAM_DOB_IMP"
              name={`Ukraina import (ZAM-DOB) ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[5]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Ukraina_ZAM_DOB_IMP_off"
                name={`Ukraina import (ZAM-DOB) ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[5]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Ukraina_RZE_CHA_EXP"
              name={`Ukraina eksport (RZE-CHA) ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[6]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Ukraina_RZE_CHA_EXP_off"
                name={`Ukraina eksport (RZE-CHA) ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[6]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="Ukraina_RZE_CHA_IMP"
              name={`Ukraina import (RZE-CHA) ${selectedDates.fromDate}`}
              stack="normal"
              axis="values"
              color={countryColors[6]}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="Ukraina_RZE_CHA_IMP_off"
                name={`Ukraina import (RZE-CHA) ${selectedDates.offsetedFromDate}`}
                stack="offset"
                axis="values"
                color={countryColorsOff[6]}
              />
            )}
            <Series
              hoverMode="nearestPoint"
              valueField="total_sum"
              name={`Suma ${selectedDates.fromDate}`}
              axis="values"
              color="#0000ff"
              type="spline"
              width={3}
            />
            {offestedDataVisible && (
              <Series
                hoverMode="nearestPoint"
                valueField="total_sum_off"
                name={`Suma ${selectedDates.offsetedFromDate}`}
                axis="values"
                color={"#00ff00"}
                type="spline"
                width={3}
              />
            )}
            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <Crosshair
              enabled={true}
              color="#949494"
              width={3}
              dashStyle="dash"
            >
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <Size height={800} />
            <Legend
              // position="outside"
              columnCount={8}
              // columnCount={8}

              verticalAlignment="bottom"
              // verticalAlignment="top"
              horizontalAlignment="center"
              // horizontalAlignment="right"
              itemTextPosition="bottom"
              // orientation="horizontal"
              // hoverMode="nearestPoint"
              markerRender={markerTemplate}
            />
            <Export enabled={true} />
            <Grid visible={true}> </Grid>
            <AdaptiveLayout width={450} keepLabels={true} />
            <Tooltip
              border={{ color: "#d3d3d3", width: 1 }}
              shadow={{ color: "#888", opacity: 0.5 }}
              interactive={true}
              arrowLength={10}
              enabled={true}
              shared={true}
              cornerRadius={5}
              contentRender={TooltipTemplate}
            >
              <Format type="largeNumber" precision={1} />
            </Tooltip>

            {/* <Grid visible={true} /> */}
          </Chart>
          <DataGrid
            onCellPrepared={cellPrepared}
            dataSource={transformedData}
            hoverStateEnabled={true}
            showBorders={true}
            remoteOperations={true}
            height={800}
            rowAlternationEnabled={true}
            onExporting={(e) =>
              onExportingDataGrid(e, `${exportFileDataName}_${pagetitle}`)
            }
          >
            <HeaderFilter visible={true} search={{ enabled: true }} />
            <Scrolling mode="virtual" />
            <Export enabled={true} formats={["csv"]} />
            <Column
              dataField="Godzina"
              dataType="string"
              allowFiltering={true}
              allowSorting={true}
              width={90}
            />
            <Column caption={selectedDates.fromDate}>
              <Column caption={"Czechy"}>
                <Column
                  caption={"Export"}
                  dataField="Czechy_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Czechy_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Słowacja"}>
                <Column
                  caption={"Export"}
                  dataField="Slowacja_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Slowacja_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Niemcy"}>
                <Column
                  caption={"Export"}
                  dataField="Niemcy_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Niemcy_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Szwecja"}>
                <Column
                  caption={"Export"}
                  dataField="Szwecja_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Szwecja_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Litwa"}>
                <Column
                  caption={"Export"}
                  dataField="Litwa_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Litwa_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                ></Column>
              </Column>
              <Column caption="Ukraina (ZAM-DOB)">
                <Column
                  caption={"Export"}
                  dataField="Ukraina_ZAM_DOB_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                ></Column>
                <Column
                  caption={"Import"}
                  dataField="Ukraina_ZAM_DOB_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                ></Column>
              </Column>
              <Column caption="Ukraina (RZE-CHA)">
                <Column
                  caption={"Export"}
                  dataField="Ukraina_RZE_CHA_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                ></Column>
                <Column
                  caption={"Import"}
                  dataField="UUkraina_RZE_CHA_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                ></Column>
              </Column>
            </Column>
            <Column
              caption={selectedDates.offsetedFromDate}
              visible={offestedDataVisible}
            >
              <Column caption={"Czechy"}>
                <Column
                  caption={"Export"}
                  dataField="Czechy_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Czechy_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Słowacja"}>
                <Column
                  caption={"Export"}
                  dataField="Slowacja_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Slowacja_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Niemcy"}>
                <Column
                  caption={"Export"}
                  dataField="Niemcy_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Niemcy_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Szwecja"}>
                <Column
                  caption={"Export"}
                  dataField="Szwecja_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Szwecja_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Litwa"}>
                <Column
                  caption={"Export"}
                  dataField="Litwa_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Litwa_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                ></Column>
              </Column>
              <Column caption="Ukraina (ZAM-DOB)">
                <Column
                  caption={"Export"}
                  dataField="Ukraina_ZAM_DOB_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                ></Column>
                <Column
                  caption={"Import"}
                  dataField="Ukraina_ZAM_DOB_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                ></Column>
              </Column>
              <Column caption="Ukraina (RZE-CHA)">
                <Column
                  caption={"Export"}
                  dataField="Ukraina_RZE_CHA_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                ></Column>
                <Column
                  caption={"Import"}
                  dataField="UUkraina_RZE_CHA_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                ></Column>
              </Column>
            </Column>
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlPwmRdn;
