export const mapColors = {
  max_wind_speed: 13,
  max_sun_power: 250,
  min_wind_speed: 0,
  // map_fill_opacity: 0.5,
  map_fill_opacity: 1,
  hsl_wind_color_deq: 240,
  hsl_sun_color_deq: 50,
  hsl_mouse_move_color_deg: 360,
};
