import React, { useEffect, useRef, useState, useCallback } from "react";
import "devextreme/data/odata/store";
import { Export, StateStoring } from "devextreme-react/data-grid";
import "./forecastsRDN.css";

import { Button, Chart, PivotGrid } from "devextreme-react";
import { Label } from "devextreme-react/form";
import { api } from "../../api/api";
import { Font, Legend, Size } from "devextreme-react/bar-gauge";
import {
  ArgumentAxis,
  AdaptiveLayout,
  CommonSeriesSettings,
  Point,
  Grid,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  ValueAxis,
} from "devextreme-react/chart";
import { FieldChooser, FieldPanel } from "devextreme-react/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import {
  BossaFromToDate,
  FromToDate,
  NewRdnData,
  RdnApiData,
  ReturnDateObject,
} from "../../types";
import DateSelector from "../../components/date-selector/DateSelector";
import { transformDate } from "../../utils/transformDate";
import { notifyMessage, sevenDayTimeSpan } from "../../utils/constants";
import notify from "devextreme/ui/notify";
import LoadPanel, { Offset } from "devextreme-react/load-panel";
import { onExportingPivotGrid } from "../../utils/onExportingCsvFile";
import {
  addOffsetToDate,
  subtractOffsetFromDate,
} from "../../utils/offsetDate";
import { forecastRdnDataSourceModel } from "../../utils/pivotGridDataSources";
import { createFileNameToExport } from "../../utils/createFileNameToExportCsv";
import { differenceInDays } from "../../utils/calcDiffBetweenDates";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import moment from "moment";
import { mergeByProperty } from "../../utils/mergeByProperty";
import { markerTemplate } from "../../utils/markerTemplates";

export default function ForecastsRDN() {
  const position = { of: "#chart" };
  const chartRef = useRef<any>(null);
  const pivotGridRef = useRef<any>(null);
  const [transformedData, setTransformedData] = useState<Array<any>>([]);
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("a");
  const [isOffseted, setIsOffseted] = useState(false);
  const rdnUrlEndpoint = "fix";
  const [selectedDates, setSelectedDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    if (pivotGridRef?.current && chartRef?.current) {
      const { instance: pivotGridInstance } = pivotGridRef.current;
      const { instance: chartInstance } = chartRef.current;

      pivotGridInstance.bindChart(chartInstance, {
        dataFieldsDisplayMode: "splitPanes",
        alternateDataFields: true,
        putDataFieldsInto: "series",
      });
    }
  }, [pivotGridRef, chartRef]);

  const getData = async (
    fromDate: string,
    toDate: string,
    daysOffset?: number
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);
    console.log(fromDate, toDate, daysOffset);
    const filter = {
      // limit: 1,
      order: "string",
      where: {
        Date: {
          $gt: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      const { data } = await api.get<any>(rdnUrlEndpoint, {
        params: { filter },
      });
      if (data?.length) {
        const updatedArray = data.map((item: any) => {
          const DaysOffset = daysOffset ? daysOffset : 0;
          const slicedDate = item.Date.slice(0, -1);
          const hours = moment(slicedDate).get("hour");

          return {
            ...item,
            model: item.model + (daysOffset ? ` - ${daysOffset} dni` : ""),
            orginalDate: moment(slicedDate).toDate(),
            Date: moment(slicedDate)
              .add(DaysOffset, "days")
              .set("hour", hours)
              .toDate(),
          };
        });
        const objectArray = [];
        type TupleType = [any, Object];
        for (const [id, obj] of Object.entries(updatedArray).filter(
          ([_, v]) => typeof v === "object"
        ) as TupleType) {
          if (daysOffset) {
            setIsOffseted(true);
            // const newObj = {} as { [key: string]: any }; // add type assertion here
            // Object.keys(obj as { [key: string]: any }).forEach((key) => {
            //   if (key !== "Date" && key !== "_id") {
            //     newObj[`${key}-offseted`] = obj[key];
            //   } else {
            //     newObj[key] = obj[key];
            //   }
            // });

            // objectArray.push({ ...newObj, offset: !!daysOffset });
            objectArray.push({ ...obj, offset: !!daysOffset });
          } else {
            setIsOffseted(false);
            objectArray.push({ ...obj, offset: !!daysOffset });
          }
        }
        setIsLoadingVisible(false);
        return objectArray;
      } else {
        setIsLoadingVisible(false);

        notify(
          notifyMessage + convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };
  const getRdnData = async (selectedDates: ReturnDateObject): Promise<void> => {
    // setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );

    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      const dateDiff = differenceInDays(
        moment(selectedDates.fromDate).toDate(),
        moment(selectedDates.offsetedFromDate).toDate()
      );
      setSelectedDates({
        fromDate: convertLongDateStringToShortFormat(selectedDates.fromDate),
        toDate: convertLongDateStringToShortFormat(
          selectedDates.offsetedFromDate
        ),
      });
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          dateDiff
        ),
      ])
        .then(([data, offsetedData]) => {
          console.log(data, offsetedData);
          if (data !== undefined && offsetedData !== undefined) {
            // const result = mergeByProperty(data, offsetedData, "Date");
            const arr = data.concat(offsetedData);
            // const result = Object.values(
            //   arr.reduce((acc, obj) => {
            //     if (!acc[obj.id]) acc[obj.id] = obj;
            //     else Object.assign(acc[obj.id], obj);
            //     return acc;
            //   }, {})
            // );

            console.log(arr);
            setTransformedData(arr as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedDates({
        fromDate: convertLongDateStringToShortFormat(selectedDates.fromDate),
      });
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      if (data) {
        setTransformedData(data as any[]);
      } else {
        setTransformedData([]);
      }
    }
  };

  // useEffect(() => {
  //   const data = setSelectedTimeDates({ selectedFromDate: new Date() });
  //   getRdnData({
  //     fromDate: new Date(),
  //     toDate: new Date(Date.now() + sevenDayTimeSpan),
  //     dateOffset: 0,
  //   });
  // }, []);

  const pivotDataSource = new PivotGridDataSource({
    fields: forecastRdnDataSourceModel as [],
    store: transformedData,
  });

  function onPointHoverChanged(e: any): void {
    const point = e.target;
    if (!point.isHovered()) {
      point.hideTooltip();
    }
  }
  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Prognozy RDN</h2>
      <DateSelector onClick={(dateObj) => getRdnData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Chart
            ref={chartRef}
            title="Wykres prognoz RDN"
            palette="Harmony Light"
            onPointHoverChanged={onPointHoverChanged}
            tooltip={{
              enabled: true,
              customizeTooltip(arg: any) {
                return {
                  text: `${arg.seriesName}<br/>${parseFloat(
                    arg.valueText
                  ).toFixed(2)} PLN`,
                };
              },
            }}
          >
            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <ArgumentAxis
              tick={(tick: any) => {
                return "abcd";
                new Date(tick.value).toLocaleString("pl-PL");
              }}
              valueMarginsEnabled={true}
              discreteAxisDivisionMode="crossLabels"
            >
              <Grid visible={true} />
            </ArgumentAxis>
            <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <Size height={500} />
            <Grid visible={true} />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
              markerRender={markerTemplate}
            />
            <Export enabled={true} />

            <CommonSeriesSettings type={`spline`}>
              <Point visible={false} hoverMode="allArgumentPoints"></Point>
            </CommonSeriesSettings>
            <AdaptiveLayout width={450} keepLabels={true} />
            {/* <ValueAxis name="frequency" position="right" tickInterval={300} /> */}
          </Chart>
          <PivotGrid
            onExporting={(e) =>
              onExportingPivotGrid(e, `${exportFileDataName}_rdn`)
            }
            id="pivotgrid"
            dataSource={pivotDataSource}
            allowSorting={true}
            allowSortingBySummary={true}
            allowFiltering={true}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={false}
            showRowTotals={false}
            showRowGrandTotals={false}
            allowExpandAll={true}
            ref={pivotGridRef}
          >
            <StateStoring
              enabled={false}
              type="localStorage"
              // storageKey="dx-widget-rdn-pivotgrid-storing"
            />
            <FieldPanel visible={true} showFilterFields={false} />
            <FieldChooser allowSearch={true} enabled={true} height={400} />
            <Export
              enabled={true}
              allowExportSelectedData={true}
              formats={["csv"]}
            />
          </PivotGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
