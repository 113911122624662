import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import {
  getRefreshToken,
  getUser,
  signIn as sendSignInRequest,
  signOut as sendSignOutRequest,
} from "../api/auth";
import type { User, AuthContextType, DecodedToken } from "../types";
import {
  accessTokenCookieName,
  cookieExpiredTimeSeconds,
  refreshTokenCookieName,
} from "../utils/constants";
import { api } from "../api/api";
import jwtDecode, { JwtPayload } from "jwt-decode";
import Cookies from "universal-cookie";
import { calcCookieExpirationDate } from "../utils/calcCookieExpirationDate";

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>({ sub: "", username: "" });
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    const refreshTokenCookie = cookies.get(refreshTokenCookieName);
    async function checkUser() {
      const { accessToken } = await getRefreshToken(refreshTokenCookie);
      const decodedAccessToken = jwtDecode<DecodedToken>(accessToken!);
      setUser({
        username: decodedAccessToken.username,
        sub: decodedAccessToken.sub,
      });
    }
    refreshTokenCookie && checkUser();
  }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    const result = await sendSignInRequest(email, password);
    const accessToken = result.data?.accessToken;
    const refreshToken = result.data?.refreshToken;

    if (result.isOk) {
      const decodedAccessToken = jwtDecode<DecodedToken>(accessToken!);
      const decodedRefreshToken = jwtDecode<DecodedToken>(refreshToken!);
      setUser({
        username: decodedAccessToken.username,
        sub: decodedAccessToken.sub,
      });
      cookies.set(accessTokenCookieName, result.data?.accessToken, {
        path: "/",
        maxAge: calcCookieExpirationDate(decodedAccessToken.exp),
      });
      cookies.set(refreshTokenCookieName, result.data?.refreshToken, {
        path: "/",
        maxAge: calcCookieExpirationDate(decodedRefreshToken.exp),
      });
    }
    return result;
  }, []);

  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if (result?.status === 200) {
      setUser({ sub: "", username: "" });
      cookies.remove(accessTokenCookieName);
      cookies.remove(refreshTokenCookieName);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading }}
      // value={{ user, signIn, signOut }}
      {...props}
    />
  );
}

const AuthContext = createContext<AuthContextType>({
  loading: false,
} as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
