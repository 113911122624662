import moment from "moment";
import React from "react";

const TooltipTemplate = (info: any, valueName: string) => {
  const sign = "[MW]";
  console.log(info, valueName);
  return (
    <div className="state-tooltip">
      <div className="info-container">
        {info && info.point.data && info.point.data.orginal_Doba_original ? (
          <>
            <h4 className="title">
              {moment(info.point.data.orginal_Doba_original).format(
                "YYYY-MM-DD HH:mm"
              )}
            </h4>
            <p className="singleInfo">
              Generacja: {info.point.data[`orginal_${valueName}`]} {sign}
            </p>
          </>
        ) : null}

        {info && info.point.data && info.point.data.offset_Doba_original ? (
          <>
            <h4 className="title">
              {moment(info.point.data.offset_Doba_original).format(
                "YYYY-MM-DD HH:mm"
              )}
            </h4>
            <p className="singleInfo">
              Generacja: {info.point.data[`offset_${valueName}`]}
              {sign}
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TooltipTemplate;
