import moment from "moment";
import React from "react";

const TooltipTemplate = (pointInfo: any) => {
  const data = pointInfo.point.data;
  console.log(data);

  type InfoItemProps = {
    label: string;
    value: string | null;
  };
  const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => {
    return value ? (
      <p className="singleInfo">
        <strong>{label}</strong>: {value}
      </p>
    ) : null;
  };
  return (
    <div className="state-tooltip">
      <h4 className="title">
        {moment(data.data_cet).format("DD-MM-YYYY HH:mm")}
      </h4>
      <div className="info-container">
        <InfoItem
          label="Parczerw"
          value={`${data.placeID1temp} ${String.fromCharCode(176)}C`}
        />
        <InfoItem
          label="Długi Kierz"
          value={`${data.placeID2temp} ${String.fromCharCode(176)}C`}
        />
      </div>
    </div>
  );
};

export default TooltipTemplate;
