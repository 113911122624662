import { SimpleItem, ButtonItem, Item } from "devextreme-react/form";
import { useEffect, useState } from "react";
import { DateBox, DateRangeBox, Form, RadioGroup } from "devextreme-react";
import "./DateWalletSelector.css";
import {
  DateSelectorProps,
  DateWalletSelectorProps,
  ReturnDateWalletObject,
  StockDataChildProps,
} from "../../types";
import {
  colCountByScreen,
  defaultDateOffsetInDays,
} from "../../utils/constants";
import {
  setSelectedDates,
  setSelectedRangeDates,
} from "../../utils/DateSelectors";
import moment from "moment";
import React from "react";
const DateWalletSelector = ({
  onClick,
  defaultOffset,
  maxRange,
}: DateWalletSelectorProps) => {
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [offsetDate, setOffsetDate] = useState(moment().toDate());
  const [directionType, setDirectionType] = useState(0);
  const [martketType, setMartketType] = useState(0);
  const [selectedToDate, setSelectedToDate] = useState(
    moment(selectedDate)
      .add(defaultOffset || maxRange || defaultDateOffsetInDays, "days")
      .toDate()
  );

  const [toDate, setToDate] = useState(
    moment(selectedDate)
      .add(maxRange || defaultOffset || defaultDateOffsetInDays, "days")
      .toDate()
  );

  const handleFromChange = (value: Date) => {
    setSelectedDate(moment(value).toDate());
    setSelectedToDate(
      moment(value)
        .add(maxRange || defaultDateOffsetInDays, "days")
        .toDate()
    );
    setToDate(
      moment(value)
        .add(maxRange || defaultDateOffsetInDays, "days")
        .toDate()
    );
    setOffsetDate(moment(value).toDate());
  };
  const handleToChange = (value: Date) => {
    setSelectedToDate(value);
  };

  const rangeSelector = {
    FromDate: selectedDate,
    ToDate: selectedToDate,
  };
  const fromDateOptions = {
    max: moment().toDate(),
  };
  const toDateOptions = {
    max: toDate,
    min: selectedDate,
  };

  const handleOffsetChange = (value: Date) => {
    setOffsetDate(moment(value).toDate());
  };
  const handleDirectionTypeChange = (value: number) => {
    setDirectionType(value === 0 || value === 1 ? value : 0);
  };
  const handleMarketTypeChange = (value: number) => {
    setMartketType(value === 0 || value === 1 || value === 2 ? value : 0);
  };
  const getSelectedRangeDates = () => {
    return setSelectedDates({
      selectedFromDate: selectedDate,
      selectedToDate: selectedToDate,
    });
  };
  useEffect(() => {
    const data = getSelectedRangeDates();
    const newObj = {
      ...data,
      directionType: directionType,
      marketType: martketType,
    };
    onClick(newObj);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = getSelectedRangeDates() as ReturnDateWalletObject;
    // data.directionType = 0;
    // data.marketType = 0;
    const newData = {
      ...data,
      directionType: directionType,
      marketType: martketType,
    };
    console.log(newData);
    onClick(newData);
  };
  const priorities = [
    { id: 0, text: "Odbiór" },
    { id: 1, text: "Generacja" },
  ];
  const priorities2 = [
    { id: 0, text: "RTT" },
    { id: 1, text: "RDN" },
    { id: 2, text: "RDB" },
  ];
  const colCountByScreen = {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 5,
  };
  return (
    <>
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={handleSubmit}>
          <Form
            className="flexStart"
            style={{ alignItems: "flex-start !important" }}
            formData={rangeSelector}
            id={"form"}
            onFieldDataChanged={(e) => {
              e.dataField === "FromDate" && handleFromChange(e.value);
              e.dataField === "ToDate" && handleToChange(e.value);
              e.dataField === "OffsetDate" && handleOffsetChange(e.value);
            }}
            colCountByScreen={colCountByScreen}
          >
            <SimpleItem
              dataField="FromDate"
              label={{ text: "Od" }}
              editorOptions={fromDateOptions}
            />

            <SimpleItem
              dataField="ToDate"
              label={{ text: "Do" }}
              editorOptions={toDateOptions}
            />

            <Item
              label={{ text: "Kierunek" }}
              component={() => (
                <RadioGroup
                  items={priorities}
                  valueExpr={"id"}
                  displayExpr={"text"}
                  defaultValue={priorities[0].id}
                  onValueChange={(e) => console.log(e, "onValueChange")}
                />
              )}
            />

            <Item
              label={{ text: "Rynek" }}
              component={() => (
                <RadioGroup
                  items={priorities2}
                  valueExpr={"id"}
                  displayExpr={"text"}
                  defaultValue={priorities2[0].id}
                  onValueChange={(e) => console.log(e, "onValueChange")}
                />
              )}
            />
            <ButtonItem
              horizontalAlignment={"left"}
              buttonOptions={{
                text: "Wybierz",
                type: "success",
                useSubmitBehavior: true,
              }}
            />
          </Form>
        </form>
      </div>
    </>
  );
};

export default DateWalletSelector;
