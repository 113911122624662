import moment from "moment";
import {
  PivotGridSourceModelInterface,
  PseNiezbRbInterface,
  StockNameCellInfo,
} from "../types";

const cellInfoNameChanger = (cellInfo: any): string => {
  switch (cellInfo.value) {
    case "se4":
      return "Se4";
      break;
    case "nordPool":
      return "Nord Pool";
      break;
    case "hupx":
      return "Hupx";
      break;
    case "lt":
      return "Lt";
      break;
    case "exaaDe":
      return "Exaa De";
      break;
    case "ote":
      return "Ote";
      break;
    case "okte":
      return "Okte";
      break;
    case "exaaAt":
      return "Exaa At";
      break;
    case "epexFrance":
      return "Epex France";
      break;
    default:
      return cellInfo.valueText ?? "";
      break;
  }
};

export const pseNiezbRbDataSourceModel: PivotGridSourceModelInterface[] = [
  {
    dataField: "Data",
    dataType: "date",
    area: "column",
  },
  {
    dataField: "Godzina",
    dataType: "number",
    area: "row",

    sortBySummaryField: "avg",
    expanded: true,
  },
  {
    dataField: "CRO",
    dataType: "number",
    area: "data",
    expanded: true,
  },
  // {
  //   dataField: "CRO",
  //   dataType: "number",
  //   area: "column",
  // },
  {
    dataField: "CROz",
    dataType: "number",
    area: "data",
  },
];

export const montelStockDataSourceModel: PivotGridSourceModelInterface[] = [
  {
    caption: "DAMC",
    dataField: "name",
    area: "row",
    sortBySummaryField: "avg",
    customizeText: (cellInfo) => cellInfoNameChanger(cellInfo),

    width: 200,
  },

  {
    dataField: "originalDate",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "year",
    caption: "Rok",
  },
  {
    dataField: "originalDate",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "month",
    caption: "Miesiąc",
  },
  {
    dataField: "originalDate",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "day",
    caption: "Dzień",
  },
  {
    dataField: "off",
    dataType: "boolean",
    visible: false,
  },
  {
    caption: "Zakres czasowy",
    dataField: "timeSpan",
    expanded: true,
    width: 65,
    area: "column",
  },
  {
    caption: "EUR/MWh",
    dataField: "value",
    dataType: "number",
    summaryType: "avg",
    format: {
      type: "currency",
      currency: "EUR",
      precision: 0,
    },
    area: "data",
  },
];

export const forecastRdnDataSourceModel: PivotGridSourceModelInterface[] = [
  {
    caption: "Model",
    dataField: "model",
    area: "row",
    expanded: true,
  },

  {
    dataField: "Date",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "year",
    caption: "Rok",
  },
  {
    dataField: "Date",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "month",
    caption: "Miesiąc",
  },
  {
    dataField: "Date",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "day",
    caption: "Dzień",
  },
  {
    caption: "Zakres czasowy",
    dataField: "Date",
    dataType: "date",
    // width: 65,
    expanded: true,
    area: "column",
    groupInterval: "hour",
    format: "HH:mm",
  },
  {
    caption: "Wartość",
    dataField: "value",
    dataType: "number",
    summaryType: "avg",

    format: {
      type: "currency",
      currency: "PLN",
      precision: 2,
    },
    area: "data",
  },
];

export const BossaDataSourceModel: any[] = [
  {
    caption: "Model",
    dataField: "model",
    area: "row",
    expanded: true,
  },

  {
    dataField: "originalDate",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "year",
    caption: "Rok",
  },
  {
    dataField: "originalDate",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "month",
    caption: "Miesiąc",
  },
  {
    dataField: "originalDate",
    dataType: "date",
    area: "column",
    expanded: true,
    groupInterval: "day",
    caption: "Dzień",
  },
  {
    caption: "Zakres czasowy",
    dataField: "timespan",

    width: 65,
    expanded: true,
    area: "column",
  },
  {
    caption: "Wartość",
    dataField: "value",
    dataType: "number",
    summaryType: "avg",
    format: {
      type: "currency",
      currency: "PLN",
      precision: 2,
    },
    area: "data",
  },
];
