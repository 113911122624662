import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { DecodedToken } from "../types";
import { api } from "./api";
import { calcCookieExpirationDate } from "../utils/calcCookieExpirationDate";
import {
  accessTokenCookieName,
  refreshTokenCookieName,
} from "../utils/constants";
import { getRefreshToken } from "./auth";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const cookies = new Cookies();
  const accessTokenCookie = cookies.get(accessTokenCookieName);
  config.headers = { Authorization: `Bearer ${accessTokenCookie}` };
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosResponse> => {
  const cookies = new Cookies();
  const refreshTokenCookie = cookies.get(refreshTokenCookieName);
  if (error.response && error.response.status === 401 && refreshTokenCookie) {
    try {
      const { accessToken } = await getRefreshToken(refreshTokenCookie);
      const originalRequest = error.config;
      if (originalRequest && originalRequest.headers) {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      }

      return api(originalRequest);
    } catch (_error: any) {
      return Promise.reject(_error);
    }
  }
  if (error.response && error.response.status === 400) {
    Promise.reject(error.response.data);
  }
  if (error.response && error.response.status === 401) {
    Promise.reject(error.response.data);
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
