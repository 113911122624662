export const pregroupedData = [
  {
    Category: "Ogólne",
    Products: [
      {
        ID: 1,
        Name: "Temperatura",
      },
      {
        ID: 2,
        Name: "Wilgotność",
      },
      {
        ID: 3,
        Name: "Zachmurzenie",
      },
      {
        ID: 4,
        Name: "Opady",
      },
    ],
  },
  {
    Category: "Prędkość wiatru",
    Products: [
      {
        ID: 13,
        Name: "wysokość 10m",
      },
      {
        ID: 14,
        Name: "wysokość 90m",
      },
      {
        ID: 15,
        Name: "wysokość 140m",
      },
      {
        ID: 16,
        Name: "porywy wiatru",
      },
    ],
  },
];
