import React, {
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  MapContainer,
  GeoJSON,
  TileLayer,
  ScaleControl,
  useMapEvents,
} from "react-leaflet";
import mapDataJSON from "../../data/district2.json";
import { mapColors } from "../../utils/mapColors";
import { LoadPanel } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { api } from "../../api/api";
import { ReturnWeatherDateObject, WeatherFromToDate } from "../../types";

import DateWeatherSelector from "../../components/date-weather-selector/DateWeatherSelector";
import CustomWeatherDataSelector from "./CustomWeatherDataSelector";
import { notifyMessage } from "../../utils/constants";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";

const Weather = () => {
  const geoJsonRef = useRef<any>(null);
  const position = { of: "#chart" };
  const weatherUrlEndpoint = "weather";
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [transformedData, setTransformedData] = useState<Array<any>>([]);
  const ref: RefObject<any> = createRef();
  const [maxDataValues, setMaxDataValues] = useState<WeatherDataValues>({
    cloudcover: 0,
    difradiation: 0,
    dniradiation: 0,
    ghiradiation: 0,
    gtiradiation: 0,
    humidity: 0,
    precip: 0,
    precipprob: 0,
    preciptype: 0,
    pressure: 0,
    snow: 0,
    snowdepth: 0,
    soiltemp01: 0,
    soiltemp04: 0,
    soiltemp10: 0,
    soiltemp20: 0,
    solarenergy: 0,
    solarradiation: 0,
    sunelevation: 0,
    temp: 0,
    winddir: 0,
    winddir50: 0,
    winddir80: 0,
    winddir100: 0,
    windgust: 0,
    windspeed: 0,
    windspeed50: 0,
    windspeed80: 0,
    windspeed100: 0,
  });

  const changeVisibility = (e: any) => {
    // setIsLoadingVisible(true);
    setSelectedOption(e);
    // setIsLoadingVisible(false);
  };

  const hslValues = {
    minWindSpeed: 260,
    maxWindSpeed: 0,
    windOffset: 60,
    minCloud: 0,
    maxCloud: 100,
    minCloudFraction: 100,
    maxCloudFraction: 10,
    minSunInsolation: 180,
    maxSunInsolation: 30,
    minHumiidity: 0,
    maxHumidity: 250,
    minTemperature: 250,
    maxTemperature: 0,
    maxPrecip: 50,
    minPrecip: 0,
  };

  const values = [
    "cloudcover",
    "difradiation",
    "dniradiation",
    "ghiradiation",
    "gtiradiation",
    "humidity",
    "precip",
    "precipprob",
    "preciptype",
    "pressure",
    "snow",
    "snowdepth",
    "soiltemp01",
    "soiltemp04",
    "soiltemp10",
    "soiltemp20",
    "solarenergy",
    "solarradiation",
    "sunelevation",
    "temp",
    "winddir",
    "winddir50",
    "winddir80",
    "winddir100",
    "windgust",
    "windspeed",
    "windspeed50",
    "windspeed80",
    "windspeed100",
  ];
  function colorPicker(
    elementVariableValue: any,
    selectedOption: any,
    maxDataValue?: any
  ): string {
    let output;
    switch (selectedOption) {
      case 0:
      case 1:
        const maxTempValue = 40;
        const minTempValue = 20;
        const calcMaxTemperature =
          (hslValues.minTemperature - hslValues.maxTemperature) /
          (maxTempValue + minTempValue);
        const hslTemperature =
          hslValues.minTemperature -
          (elementVariableValue + minTempValue) * calcMaxTemperature;
        output = `hsl(${hslTemperature}, 100%, 50%)`;

        break;
      case 2:
        const calcMaxHumidityValues =
          (hslValues.maxHumidity - hslValues.minHumiidity) / 100;

        const hslHumidityVal =
          hslValues.minHumiidity + elementVariableValue * calcMaxHumidityValues;
        output = `hsl(${hslHumidityVal}, 100%, 50%)`;

        break;
      case 3:
        const caclMaxCloudFraction =
          (hslValues.minCloudFraction - hslValues.maxCloudFraction) /
          maxDataValue;
        const hslCloudFractionVal =
          hslValues.minCloudFraction -
          elementVariableValue * caclMaxCloudFraction;
        output = `hsl(0, 0%, ${hslCloudFractionVal}%)`;

        break;
      case 4:
        const maxPrecipValue =
          elementVariableValue >= 20 ? 20 : elementVariableValue;
        const hslPrecipVal = 100 - maxPrecipValue * 2.5;

        output = `hsl(240, 100%, ${hslPrecipVal}%)`;
        break;
      case 5:
      case 6:

      case 7:
      case 8:
      case 9:
        const caclMaxSunInsolation =
          (hslValues.minSunInsolation - hslValues.maxSunInsolation) /
          maxDataValue;
        const hslSunInsolation =
          hslValues.minSunInsolation -
          elementVariableValue * caclMaxSunInsolation;
        output = `hsl(${hslSunInsolation}, 100%, 50%)`;

        break;
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        const maxWindSpeedInMeterPerSecond = 33;
        const minWindSpeedInMeterPerSecond = 0;
        const calcMaxWindSpeed =
          (hslValues.minWindSpeed - hslValues.maxWindSpeed) /
          (maxWindSpeedInMeterPerSecond + minWindSpeedInMeterPerSecond);
        const hslWindSpeed =
          hslValues.minWindSpeed -
          (elementVariableValue + minWindSpeedInMeterPerSecond) *
            calcMaxWindSpeed -
          hslValues.windOffset;
        output = `hsl(${hslWindSpeed}, 100%, 50%)`;

        break;
      case 17:
        const caclMaxCloudValues =
          (hslValues.maxCloud - hslValues.minCloud) / 100;
        const hslCloudVal =
          hslValues.maxCloud +
          hslValues.minCloud -
          (hslValues.minCloud + elementVariableValue * caclMaxCloudValues);

        output = !isNaN(hslCloudVal)
          ? `hsl(360, 0%, ${hslCloudVal}%)`
          : `hsla(360, 100%, 50%, 0.5)`;
        break;

      default:
        output = "0";
        break;
    }

    return output;
  }
  const setCountyColorHSL = (element: any, countyId: any) => {
    const output = {
      color: "hsla(360, 100%, 50%, 0.5)",
      activeTile: element.data[0].temp,
    };
    const {
      cloudcover,
      humidity,
      precip,
      temp,
      windgust,
      windspeed50,
      windspeed80,
      windspeed100,
    } = element.data[0];

    switch (selectedOption) {
      case 1:
        output.color = colorPicker(
          parseFloat(temp),
          selectedOption,
          maxDataValues.temp
        );
        output.activeTile = temp;
        break;
      case 2:
        output.color = colorPicker(
          parseFloat(humidity),
          selectedOption,
          maxDataValues.relative_humidity
        );
        output.activeTile = humidity;
        break;
      case 3:
        output.color = colorPicker(
          parseFloat(cloudcover),
          selectedOption,
          maxDataValues.cloudcover
        );
        output.activeTile = cloudcover;
        break;
      case 4:
        output.color = colorPicker(
          parseFloat(precip),
          selectedOption,
          maxDataValues.precip
        );
        output.activeTile = precip;
        break;
      case 5:
        output.color = colorPicker(
          parseFloat(element.cloud_fraction_high),
          selectedOption,
          maxDataValues.cloud_fraction_high
        );
        output.activeTile = element.cloud_fraction_high;
        break;
      case 6:
        output.color = colorPicker(
          parseFloat(cloudcover),
          selectedOption,
          maxDataValues.cloudcover
        );
        output.activeTile = cloudcover;
        break;
      case 7:
        output.color = colorPicker(
          parseFloat(element.diffuse_horizontal_insolation),
          selectedOption,
          maxDataValues.diffuse_horizontal_insolation
        );
        output.activeTile = element.diffuse_horizontal_insolation;
        break;
      case 8:
        output.color = colorPicker(
          parseFloat(element.direct_horizontal_insolation),
          selectedOption,
          maxDataValues.direct_horizontal_insolation
        );
        output.activeTile = element.direct_horizontal_insolation;
        break;
      case 9:
        output.color = colorPicker(
          parseFloat(element.global_horizontal_insolation),
          selectedOption,
          maxDataValues.global_horizontal_insolation
        );
        output.activeTile = element.global_horizontal_insolation;
        break;
      case 10:
        output.color = colorPicker(
          parseFloat(element.wind_10m_direction),
          selectedOption,
          maxDataValues.wind_10m_direction
        );
        output.activeTile = element.wind_10m_direction;
        break;
      case 11:
        output.color = colorPicker(
          parseFloat(element.wind_90m_direction),
          selectedOption,
          maxDataValues.wind_90m_direction
        );
        output.activeTile = element.wind_90m_direction;
        break;
      case 12:
        output.color = colorPicker(
          parseFloat(element.wind_140m_direction),
          selectedOption,
          maxDataValues.wind_140m_direction
        );
        output.activeTile = element.wind_140m_direction;
        break;
      case 13:
        output.color = colorPicker(
          parseFloat(windspeed50),
          selectedOption,
          maxDataValues.windspeed50
        );
        output.activeTile = windspeed50;
        break;
      case 14:
        output.color = colorPicker(
          parseFloat(windspeed80),
          selectedOption,
          maxDataValues.windspeed80
        );
        output.activeTile = windspeed80;
        break;
      case 15:
        output.color = colorPicker(
          parseFloat(windspeed100),
          selectedOption,
          maxDataValues.windspeed100
        );
        output.activeTile = windspeed100;
        break;
      case 16:
        output.color = colorPicker(
          parseFloat(windgust),
          selectedOption,
          maxDataValues.windgust
        );
        output.activeTile = windgust;
        break;
      default:
        output.color = colorPicker(
          parseFloat(element.temperature),
          selectedOption,
          maxDataValues.temperature
        );
        output.activeTile = element.temperature;
        break;
    }

    return output;
  };
  const changeCountryColoronMouseMove = (event: any, color: any) => {
    event.setStyle({
      fillColor: color,
    });
  };
  const popupOptions = { className: "map-custom-popup" };
  const onEachCounty = (county: any, layer: any) => {
    const countyName = county.properties.nazwa;
    const countyId = county.properties.id;
    const element = transformedData.find(
      (element) => element.placeID === county.properties.id
    );
    const {
      cloudcover,
      humidity,
      precip,
      precipprob,
      temp,
      winddir,
      winddir50,
      winddir80,
      winddir100,
      windgust,
      windspeed,
      windspeed50,
      windspeed80,
      windspeed100,
    } = element.data[0];

    const popUpContent = `<p class="title">
    <b>${countyName}</b></p>
    <p>temperatura: <b>${temp} &#8451;</b></p>
    <p>wilgotność: <b>${humidity} %</b></p>
    <p>zachmurzenie: <b>${cloudcover} %</b></p>
    <p>prawdopodobieństwo opadów : <b>${precipprob} %</b></p>
    <p>suma opadów: <b>${precip}</b></p>
    <p>prędkość wiatru (50m) :<span style="transform: rotate(${winddir50}deg)">&#8593;</span> <b>${windspeed50} m/s</b></p>
    <p>prędkość wiatru (80m) : <span style="transform: rotate(${winddir80}deg)">&#8593;</span> <b>${windspeed80} m/s</b></p>
    <p>prędkość wiatru (100m) : <span style="transform: rotate(${winddir100}deg)">&#8593;</span> <b>${windspeed100} m/s</b></p>
    <p>porywy wiatru : <b>${windgust} m/s</b></p>
     `;
    const activeLayerInfo = setCountyColorHSL(element, countyId);
    layer.bindPopup(popUpContent, popupOptions);
    layer.setStyle({
      fillColor: `${activeLayerInfo.color}`,
    });
    const activeLayer = activeLayerInfo.activeTile;
    layer.bindTooltip(`${activeLayer}`, {
      permanent: true,
      direction: "center",
      opacity: 1,
      fontSize: "0.5rem",
      className: "tooltipxs",
      color: "red",
    });
    layer.on({
      mouseover: (e: any) => {
        changeCountryColoronMouseMove(layer, "red");
      },
      mouseout: (e: any) => {
        changeCountryColoronMouseMove(layer, activeLayerInfo.color);
      },
    });
  };

  const getData = async (fromDate: string): Promise<WeatherDataValues[]> => {
    setIsLoadingVisible(true);
    const filter = {
      limit: 380,
      skip: 0,
      order: "string",
      where: {
        data_cet: {
          $eq: fromDate,
        },
      },
      fields: {
        _id: true,
        data_cet: true,
        czas_aktualizacji: true,
        placeID: true,
        lat: true,
        lng: true,
        district: true,
        place: true,
        data: true,
      },
    };

    try {
      const { data } = await api.get<any>(weatherUrlEndpoint, {
        params: { filter },
      });
      if (data?.length) {
        setIsLoadingVisible(false);
        return data;
      } else {
        setIsLoadingVisible(false);
        notify(
          notifyMessage + convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return [];
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return [];
    }
  };

  type WeatherDataValues = {
    [key: string]: number;
  };
  const searchMaxValues = (data: any[]) => {
    const maxValues: WeatherDataValues = data?.length
      ? Object.fromEntries(
          values.map((key) => [
            key,
            Math.max(
              ...data
                .flatMap((obj) => obj.data)
                .map((obj) => (isNaN(obj[key]) ? 0 : obj[key]))
            ),
          ])
        )
      : {};
    return maxValues;
  };

  const getWeatherData = async (
    dates: ReturnWeatherDateObject
  ): Promise<void> => {
    const data = await getData(dates.fromDate);
    const maxValues = searchMaxValues(data);

    setMaxDataValues(maxValues);
    setTransformedData(data as any);
  };
  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Prognoza pogody</h2>
      <DateWeatherSelector onClick={(dateObj) => getWeatherData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <MapContainer
            ref={ref}
            center={[52.11, 19.21]}
            zoom={7}
            minZoom={6}
            scrollWheelZoom={true}
            dragging={true}
            maxZoom={10}
          >
            {/* <ZoomControl selectedOption={selectedOption} /> */}
            <ScaleControl position="bottomleft" />
            <CustomWeatherDataSelector
              // onRadioChange={(e: any) => setSelectedOption(e)}
              onRadioChange={(e: any) => changeVisibility(e)}
            />
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={10}
              minZoom={6}
              attribution='&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
            />
            {/* !isLoadingVisible && */}
            {!isLoadingVisible && transformedData?.length && (
              <GeoJSON
                ref={geoJsonRef}
                key={selectedOption}
                //   type="FeatureCollection"
                data={mapDataJSON as any}
                onEachFeature={onEachCounty}
                style={() => ({
                  color: "#555",

                  weight: 1.5,
                  fillOpacity: mapColors.map_fill_opacity,
                })}
              />
            )}
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default Weather;
