import moment from "moment";
import React from "react";

const TooltipTemplate = (pointInfo: any) => {
  const countries = [
    { key: "CRO", name: "CRO" },
    { key: "CROs", name: "CROs" },
    { key: "CROz", name: "CROz" },
    { key: "Niezbilansowanie", name: "Niezbilansowanie" },
    { key: "Stan_zakontraktowania", name: "Stan zakontraktowania" },
  ];
  const pointData = pointInfo?.point?.data || null;

  const createDate = (data: string | undefined, hour: number | undefined) => {
    return data
      ? moment(data)
          .set("hour", hour || 0)
          .format("YYYY-MM-DD HH:mm")
      : null;
  };

  const currentDate = createDate(pointData?.Data, pointData?.Godzina);
  const historyDate = createDate(pointData?.Data_off, pointData?.Godzina);

  const renderData = (data: any, key: string) =>
    typeof data[key] === "number" ? data[key] : "brak danych";

  return (
    <div className="state-tooltip" style={{ display: "flex", gap: "10px" }}>
      <div>
        <h4 className="title">{currentDate}</h4>
        <div className="info-container">
          {countries.map((country: any) => (
            <p className="singleInfo" key={`${country.key}`}>
              {country.name} :<b> {renderData(pointData, `${country.key}`)}</b>
            </p>
          ))}
        </div>
      </div>
      {historyDate && (
        <div>
          <h4 className="title">{historyDate}</h4>
          <div className="info-container">
            {countries.map((country: any) => (
              <p className="singleInfo" key={`${country.key}_off`}>
                {country.name} :
                <b> {renderData(pointData, `${country.key}_off`)}</b>
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipTemplate;
