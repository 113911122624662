import { useCallback } from "react";
import { Workbook } from "exceljs";
import { exportPivotGrid, exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

export const onExportingPivotGrid = (e: any, fileName: String) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Montel");
  exportPivotGrid({
    component: e.component,
    worksheet: worksheet,
    encodeExecutableContent: true,
  }).then(function () {
    workbook.csv.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${fileName}.csv`
      );
    });
  });
  e.cancel = true;
};

export const onExportingDataGrid = (e: any, fileName: String) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Keno energia");
  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    encodeExecutableContent: true,
  }).then(function () {
    workbook.csv.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${fileName}.csv`
      );
    });
  });
  e.cancel = true;
};
