import React, { useEffect, useRef, useState, useCallback } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Export,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Label,
  Paging,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "./bossa.css";
import { api } from "../../api/api";
import { ReturnDateObject } from "../../types";
import { Workbook } from "exceljs";
import { exportDataGrid, exportPivotGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import DateTimeSelector from "../../components/date-time-selector/DateTimeSelector";
import ArrayStore from "devextreme/data/array_store";
import { Chart, LoadPanel, PivotGrid } from "devextreme-react";
import { Size, Legend, Font } from "devextreme-react/bar-gauge";
import notify from "devextreme/ui/notify";
import {
  Grid,
  CommonSeriesSettings,
  Point,
  AdaptiveLayout,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import { onExportingDataGrid as onExporting } from "../../utils/onExportingCsvFile";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import { createFileNameToExport } from "../../utils/createFileNameToExportCsv";
import { FieldPanel, FieldChooser } from "devextreme-react/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { forecastRdnDataSourceModel } from "../../utils/pivotGridDataSources";
import { RowDblClickEvent } from "devextreme/ui/data_grid";
import TooltipTemplate from "./TooltipTemplate";
import moment from "moment";
import { markerTemplate } from "../../utils/markerTemplates";
import { notifyMessage } from "../../utils/constants";

export default function Bossa() {
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [isOffseted, setIsOffseted] = useState(false);
  const displayedDateFormat = "DD-MM-YYYY HH:mm";
  const [transformedData, setTransformedData] = useState<any>([]);
  const [selectedDates, setSelectedDates] = useState<any>({
    fromDate: "",
    offsetedFromDate: "",
  });
  const bossaUrlEndpoint = "bossa";
  const position = { of: "#chart" };
  // useEffect(() => {
  //   if (pivotGridRef?.current && chartRef?.current) {
  //     const { instance: pivotGridInstance } = pivotGridRef.current;
  //     const { instance: chartInstance } = chartRef.current;
  //     pivotGridInstance.bindChart(chartInstance, {
  //       dataFieldsDisplayMode: "splitPanes",
  //       alternateDataFields: true,
  //       putDataFieldsInto: "series",
  //     });
  //     // chartInstance.option("palette", "Soft Pastel");
  //   }
  // }, [pivotGridRef, chartRef]);
  const cellPrepared = (e: any) => {
    if (e.rowType === "data") {
      if (e.column.dataField === "Godzina") {
        e.cellElement.style.cssText = "background-color: #f5f5f5";
      }
    }
  };
  const getData = async (
    fromDate: string,
    toDate: string,
    offset?: boolean
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);
    console.log(fromDate, toDate, offset);
    const filter = {
      limit: 1,
      order: "string",
      where: {
        czas: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      const { data } = await api.get<any>(bossaUrlEndpoint, {
        params: { filter },
      });

      console.log(data);
      const dataArr = data?.[0]?.data;
      if (data?.length) {
        const objectArray = [];
        type TupleType = [any, Object];
        for (const [id, obj] of Object.entries(dataArr).filter(
          ([_, v]) => typeof v === "object"
        ) as TupleType) {
          if (offset) {
            setIsOffseted(true);
            const newObj = {} as { [key: string]: any }; // add type assertion here
            Object.keys(obj as { [key: string]: any }).forEach((key) => {
              newObj[`${key}-offseted`] = obj[key];
            });
            objectArray.push({ id: parseInt(id), ...newObj, offset: !!offset });
          } else {
            setIsOffseted(false);
            objectArray.push({ id: parseInt(id), ...obj, offset: !!offset });
          }
        }

        setIsLoadingVisible(false);
        return objectArray;
      } else {
        setIsLoadingVisible(false);

        notify(
          notifyMessage + convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify(
        notifyMessage + convertLongDateStringToShortFormat(fromDate),
        "warning",
        2000
      );
      return undefined;
    }
  };

  const getBossaData = async (
    selectedDates: ReturnDateObject
  ): Promise<void> => {
    setExportFileDataName(
      createFileNameToExport(
        "Krzywe zagregowane",
        selectedDates.fromDate,
        selectedDates.offsetedFromDate
      )
    );

    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format(displayedDateFormat),
        offsetedFromDate: moment(selectedDates.offsetedFromDate).format(
          displayedDateFormat
        ),
      });
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          true
        ),
      ])
        .then(([data, offsetedData]) => {
          console.log(data, offsetedData);
          if (data !== undefined && offsetedData !== undefined) {
            const arr = data.concat(offsetedData);
            const result = Object.values(
              arr.reduce((acc, obj) => {
                if (!acc[obj.id]) acc[obj.id] = obj;
                else Object.assign(acc[obj.id], obj);
                return acc;
              }, {})
            );
            setTransformedData(result as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format(displayedDateFormat),
      });
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      setTransformedData(data as any[]);
    }
  };

  const store = new ArrayStore({
    key: "id",
    data: transformedData,
  });

  const seriesDataArr = [
    {
      valueField: "cenaKupna",
      name: `Cena kupna ${selectedDates.fromDate}`,
      argumentField: "skumWolKupna",
      color: "#679EC5",
    },
    {
      valueField: "cenaSprzedazy",
      name: `Cena sprzedaży ${selectedDates.fromDate}`,
      argumentField: "skumWolSprzedazy",
      color: "#E18E92",
    },
  ];
  const seriesDataOffsetedArr = [
    {
      valueField: "cenaKupna",
      name: `Cena kupna ${selectedDates.fromDate}`,
      argumentField: "skumWolKupna",
      color: "#679EC5",
    },
    {
      valueField: "cenaSprzedazy",
      name: `Cena sprzedaży ${selectedDates.fromDate}`,
      argumentField: "skumWolSprzedazy",
      color: "#E18E92",
    },
    {
      valueField: "cenaKupna-offseted",
      name: `Cena kupna ${selectedDates.offsetedFromDate}`,
      argumentField: "skumWolKupna-offseted",
      color: "#123ec5",
      dashStyle: "dash",
    },
    {
      valueField: "cenaSprzedazy-offseted",
      name: `Cena sprzedaży ${selectedDates.offsetedFromDate}`,
      argumentField: "skumWolSprzedazy-offseted",
      color: "#ab5afa",
      dashStyle: "dash",
    },
  ];

  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={false}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Krzywe zagregowane z fix 1</h2>
      <DateTimeSelector onClick={(dateObj) => getBossaData(dateObj)} />
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Chart
            redrawOnResize={true}
            onLegendClick={(e) =>
              e.target.isVisible() ? e.target.hide() : e.target.show()
            }
            dataSource={store}
            series={isOffseted ? seriesDataOffsetedArr : seriesDataArr}
            // tooltip={{
            //   enabled: true,
            //   customizeTooltip(arg: any) {
            //     return {
            //       text: `${arg.seriesName}<br/>cena: ${parseFloat(
            //         arg.valueText
            //       ).toFixed(2)} &#8364;<br/>wolumen: ${arg.argumentText}`,
            //     };
            //   },
            // }}
          >
            {/* <Point visible={false} /> */}
            <ValueAxis name="generatedValue" position="left" title="PLN/MWh">
              <Grid visible={true} />
            </ValueAxis>
            <Size height={500} />
            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <AdaptiveLayout width={450} keepLabels={true} />
            <Crosshair
              enabled={true}
              color="#949494"
              width={3}
              dashStyle="solid"
            >
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <Grid visible={true} />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
              markerRender={markerTemplate}
            />
            <Export enabled={true} />

            <CommonSeriesSettings type={`spline`}>
              <Point visible={true} size={7} symbol="circle"></Point>
            </CommonSeriesSettings>
            <AdaptiveLayout width={450} keepLabels={true} />
            <Tooltip
              border={{ color: "#d3d3d3", width: 1 }}
              shadow={{ color: "#888", opacity: 0.5 }}
              interactive={true}
              arrowLength={10}
              enabled={true}
              shared={true}
              cornerRadius={5}
              contentRender={TooltipTemplate}
            ></Tooltip>
          </Chart>
          <DataGrid
            onCellPrepared={cellPrepared}
            hoverStateEnabled={true}
            showBorders={true}
            remoteOperations={true}
            height={800}
            rowAlternationEnabled={true}
            onExporting={(e) => onExporting(e, `${exportFileDataName}`)}
            dataSource={store}
            showRowLines={true}
          >
            {/* <FilterRow visible={true} applyFilter="auto" /> */}
            {/* <FilterPanel visible={true} defaultFilterEnabled={true} /> */}
            <Export enabled={true} />
            {/* <Scrolling columnRenderingMode="virtual" /> */}

            <Scrolling mode="virtual" />
            <Column
              alignment="center"
              dataField="id"
              dataType="number"
              caption={"Numer transakcji"}
            />
            <Column caption={"Cena sprzedaży (PLN)"} alignment="center">
              <Column
                alignment="right"
                dataField="cenaSprzedazy"
                dataType="number"
                caption={` ${selectedDates.fromDate}`}
              />
              {selectedDates.offsetedFromDate && (
                <Column
                  visible={isOffseted}
                  alignment="right"
                  dataField="cenaSprzedazy-offseted"
                  dataType="number"
                  caption={` ${selectedDates.offsetedFromDate}`}
                />
              )}
            </Column>
            <Column caption={"Skum wol sprzedaży (MWh)"} alignment="center">
              <Column
                dataField="skumWolSprzedazy"
                dataType="number"
                caption={` ${selectedDates.fromDate}`}
              />
              {selectedDates.offsetedFromDate && (
                <Column
                  visible={isOffseted}
                  dataField="skumWolSprzedazy-offseted"
                  dataType="number"
                  caption={` ${selectedDates.offsetedFromDate}`}
                />
              )}
            </Column>
            <Column caption={"Cena kupna (PLN)"} alignment="center">
              <Column
                dataField="cenaKupna"
                dataType="number"
                caption={` ${selectedDates.fromDate}`}
              />
              {selectedDates.offsetedFromDate && (
                <Column
                  visible={isOffseted}
                  dataField="cenaKupna-offseted"
                  dataType="number"
                  caption={` ${selectedDates.offsetedFromDate}`}
                />
              )}
            </Column>

            <Column caption={"Skum wol kupna (MWh)"} alignment="center">
              <Column
                dataField="skumWolKupna"
                dataType="number"
                caption={` ${selectedDates.fromDate}`}
              />
              {selectedDates.offsetedFromDate && (
                <Column
                  visible={isOffseted}
                  dataField="skumWolKupna-offseted"
                  dataType="number"
                  caption={` ${selectedDates.offsetedFromDate}`}
                />
              )}
            </Column>

            <Paging defaultPageSize={99999} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
