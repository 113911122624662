import moment from "moment";
import React from "react";

const TooltipTemplate = (info: any, valueName: string) => {
  const sign = "[MW]";
  return (
    <div className="state-tooltip">
      <div className="info-container">
        {info && info.point.data && info.point.data.Data_original ? (
          <>
            <h4 className="title">
              {moment(info.point.data.Data_original).format("YYYY-MM-DD HH:mm")}
            </h4>
            <p className="singleInfo">
              BPKD Generacja: {info.point.data[`first_${valueName}`]} {sign}
            </p>
            <p className="singleInfo">
              PKD Generacja: {info.point.data[`newest_${valueName}`]} {sign}
            </p>
            <p className="singleInfo">
              &#916; Generacja: {info.point.data[`delta_${valueName}`]} {sign}
            </p>
          </>
        ) : null}

        {info && info.point.data && info.point.data.offset_Data_original ? (
          <>
            <h4 className="title">
              {moment(info.point.data.offset_Data_original).format(
                "YYYY-MM-DD HH:mm"
              )}
            </h4>
            <p className="singleInfo">
              BPKD Generacja: {info.point.data[`offset_first_${valueName}`]}
              {sign}
            </p>
            <p className="singleInfo">
              PKD Generacja: {info.point.data[`offset_newest_${valueName}`]}
              {sign}
            </p>
            <p className="singleInfo">
              &#916; Generacja: {info.point.data[`delta_offset_${valueName}`]}
              {sign}
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TooltipTemplate;
