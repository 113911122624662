export const navigation = [
  {
    text: "Strona główna",
    path: "/home",
    icon: "home",
  },

  // {
  //   text: "Historia",
  //   path: "/history",
  //   icon: "chart",
  // },
  {
    text: "Prognoza pogody",
    path: "/weather",
    icon: "chart",
  },
  // {
  //   text: "PKD",
  //   path: "/pkd",
  //   icon: "chart",
  // },
  {
    text: "Krzywe zagregowane",
    icon: "chart",
    items: [
      {
        text: "Krzywe zagr. z fix1",
        path: "/krzywe-zagregowane",
        icon: "chart",
      },
    ],
  },
  {
    text: "Rynek dnia następnego",
    icon: "rdn",
    items: [
      {
        text: "DAMC",
        path: "/damc",
        icon: "chart",
      },
      {
        text: "Wymiana międzysystemowa RDN",
        path: "/pl-pwm-rdn",
        icon: "chart",
      },
      {
        text: "PK5",
        path: "/go-bilans",
        icon: "chart",
      },
      // {
      //   text: "Raporty PSE",
      //   icon: "pse",
      //   items: [
      //     {
      //       text: "PK5",
      //       path: "/pk5",
      //     },
      //     {
      //       text: "Wymiana Międzysystemowa RDN",
      //       path: "/wymiana-rdn",
      //     },
      //   ],
      // },
      {
        text: "Prognozy RDN",
        icon: "rdn",
        items: [
          {
            text: "Prognozy RDN",
            path: "/prognozy-rdn",
          },
          {
            text: "Arbitraż DAMC - Fix1",
            path: "/fix1",
          },
        ],
      },
      // {
      //   text: "Market Coupling",
      //   icon: "mc",
      //   items: [],
      // },
    ],
  },
  {
    text: "Rynek dnia bieżącego",
    icon: "rdb",
    items: [
      {
        text: "Ceny TGE RDB",
        path: "/tge-rdb",
        icon: "chart",
      },
      {
        text: "Wymiana międzysystemowa RDB",
        path: "/pl-pwm-rdb",
        icon: "chart",
      },
    ],
  },
  {
    text: "Rynek bilansujący",
    icon: "rb",
    items: [
      {
        text: "CRO i niezbilansowanie",
        path: "/cro-i-niezbilansowanie",
        icon: "chart",
      },

      {
        text: "BPKD",
        path: "/bpkd",
        icon: "chart",
      },
    ],
  },
  {
    text: "Rynek terminowy",
    icon: "rt",
    items: [],
  },
  {
    text: "Portfel",
    path: "/wallet",
    icon: "chart",
  },
];
