import React, { useEffect, useState } from "react";
import DataGrid, {
  Scrolling,
  Paging,
  Column,
  HeaderFilter,
  Pager,
} from "devextreme-react/data-grid";
import { api } from "../../api/api";
import CustomStore from "devextreme/data/custom_store";
import "./history.scss";

const History = () => {
  const customLoadParams = {
    filter: { offset: 0, limit: 10, skip: 0 },
    requireTotalCount: true,
  };

  const store = new CustomStore({
    key: "TickerSymbol",
    async load() {
      const data = await api.get(
        `histories?filter=%7B%0A%20%20%22offset%22%3A%200%2C%0A%20%20%22limit%22%3A%2048%2C%0A%20%20%22skip%22%3A%200%0A%7D`
      );
      return {
        data: data.data,
        totalCount: 615000, // if requireTotalCount = true
        // summary: [170, 20, 20, 1020],
      };
    },
  });

  const allowedPageSizes = [12, 24, 48];
  return (
    <DataGrid
      dataSource={store}
      columnWidth={70}
      showBorders={true}
      remoteOperations={true}
    >
      <HeaderFilter visible={true} allowSearch={true} />
      <Scrolling columnRenderingMode="virtual" />
      {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
      {/* <Paging defaultPageSize={100} /> */}
      <Column dataField="TickerSymbol" dataType="string" width={130} />
      <Column dataField="MontelSymbol" dataType="string" width={90} />
      <Column dataField="MarketName" dataType="string" />
      <Column dataField="ContractName" dataType="string" />
      <Column dataField="MarketCode" dataType="string" />
      {/* <Column dataField="F" dataType="string" /> */}
      <Column dataField="GenericPeriod" dataType="string" />
      <Column dataField="CommodityGroup" dataType="string" />
      <Column dataField="CommodityType" dataType="string" />
      <Column dataField="Currency" dataType="string" width={70} />
      <Column dataField="Denomination" dataType="string" />
      <Column dataField="SpecificPeriod" dataType="string" width={110} />
      <Column dataField="ContractYear" dataType="string" />
      <Column dataField="Spread" dataType="string" />
      <Column dataField="Etc" dataType="string" width={70} />
      <Column dataField="SourceId" dataType="string" />
      <Column dataField="BasePeakType" dataType="string" />
      <Column dataField="ContractSize" dataType="string" width={60} />
      <Column dataField="Liquidity" dataType="string" width={60} />
      <Column dataField="TradingStart" dataType="string" />
      <Column dataField="TradingEnd" dataType="string" />
      <Column dataField="DeliveryStart" dataType="string" />
      <Column dataField="X" dataType="string" />
      <Column dataField="Y" dataType="string" />
      <Column dataField="Z" dataType="string" />
      <Column dataField="AA" dataType="string" />
      <Paging defaultPageSize={24} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        showNavigationButtons={true}
        displayMode={"compact"}
      />
    </DataGrid>
  );
};

export default History;
