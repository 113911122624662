export const listName = [
  [
    "first_Krajowe_zapotrzebowanie_na_moc",
    "newest_Krajowe_zapotrzebowanie_na_moc",
  ],
  [
    "first_Rezerwa_mocy_ponizej_zapotrzebowania",
    "newest_Rezerwa_mocy_ponizej_zapotrzebowania",
  ],
  [
    "first_Rezerwa_mocy_ponad_zapotrzebowanie",
    "newest_Rezerwa_mocy_ponad_zapotrzebowanie",
  ],
  [
    "first_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
    "newest_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
  ],
  [
    "first_Generacja_zrodel_fotowoltaicznych",
    "newest_Generacja_zrodel_fotowoltaicznych",
  ],
  ["first_Generacja_zrodel_wiatrowych", "newest_Generacja_zrodel_wiatrowych"],
  [
    "offset_first_Krajowe_zapotrzebowanie_na_moc",
    "offset_newest_Krajowe_zapotrzebowanie_na_moc",
  ],
  [
    "offset_first_Rezerwa_mocy_ponizej_zapotrzebowania",
    "offset_newest_Rezerwa_mocy_ponizej_zapotrzebowania",
  ],
  [
    "offset_first_Rezerwa_mocy_ponad_zapotrzebowanie",
    "offset_newest_Rezerwa_mocy_ponad_zapotrzebowanie",
  ],
  [
    "offset_first_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
    "offset_newest_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
  ],
  [
    "offset_first_Generacja_zrodel_fotowoltaicznych",
    "offset_newest_Generacja_zrodel_fotowoltaicznych",
  ],
  [
    "offset_first_Generacja_zrodel_wiatrowych",
    "offset_newest_Generacja_zrodel_wiatrowych",
  ],
];
