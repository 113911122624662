// import axios from "axios";
// import { getCookie } from "../utils/getCookie";

// const baseApiURL = "https://www.dev-energy.pl:4500/";
// // const baseApiURL = "http://localhost:3000/";

// export const api = axios.create({
//   baseURL: baseApiURL,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Content-Type": "application/json",
//   },
// });

// api.interceptors.request.use(
//   (config) => {
//     const token = getCookie("token");
//     if (token) {
//       config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

import axios from "axios";
import { setupInterceptorsTo } from "./interceptors";

const baseApiURL = process.env.REACT_APP_BASE_API_URL;

export const api = setupInterceptorsTo(
  axios.create({
    baseURL: baseApiURL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      // "Referrer-Policy": "no-referrer-when-downgrade",
    },
  })
);
