export const markerTemplate = (item: any) => {
  const color = item.series.isVisible() ? item.marker.fill : "#888";
  return (
    <svg>
      <rect
        x={0}
        y={0}
        width={18}
        height={18}
        fill={color}
        rx={2}
        ry={2}
        opacity={1}
      ></rect>
    </svg>
  );
};
