import React, { useEffect, useRef, useState, useCallback } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Export,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Label,
  Paging,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "./bossa.css";
import { api } from "../../api/api";
import { ReturnDateWalletObject } from "../../types";
import { Workbook } from "exceljs";
import { exportDataGrid, exportPivotGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import DateTimeSelector from "../../components/date-time-selector/DateTimeSelector";
import ArrayStore from "devextreme/data/array_store";
import { Chart, LoadPanel, PivotGrid } from "devextreme-react";
import { Size, Legend, Font, Format, Title } from "devextreme-react/bar-gauge";
import notify from "devextreme/ui/notify";
import {
  Grid,
  CommonSeriesSettings,
  Point,
  AdaptiveLayout,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Tooltip,
  ValueAxis,
  ArgumentAxis,
  Series,
} from "devextreme-react/chart";
import { onExportingDataGrid as onExporting } from "../../utils/onExportingCsvFile";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import { createFileNameToExport } from "../../utils/createFileNameToExportCsv";
import { FieldPanel, FieldChooser } from "devextreme-react/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { forecastRdnDataSourceModel } from "../../utils/pivotGridDataSources";
import { RowDblClickEvent } from "devextreme/ui/data_grid";
import TooltipTemplate from "./TooltipTemplate";
import moment from "moment";
import { markerTemplate } from "../../utils/markerTemplates";
import DateWalletSelector from "../../components/date-wallet-selector/DateWalletSelector";
import { notifyMessage } from "../../utils/constants";
import TemperatureChart from "./TemperatureChart";

export default function Wallet() {
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [isOffseted, setIsOffseted] = useState(false);
  const displayedDateFormat = "YYYY-MM-DD";
  const [transformedData, setTransformedData] = useState<any>([]);
  const [selectedDates, setSelectedDates] = useState<any>({
    fromDate: "",
    offsetedFromDate: "",
  });
  const walletUrlEndpoint = "wallet";
  const position = { of: "#chart" };
  // useEffect(() => {
  //   if (pivotGridRef?.current && chartRef?.current) {
  //     const { instance: pivotGridInstance } = pivotGridRef.current;
  //     const { instance: chartInstance } = chartRef.current;
  //     pivotGridInstance.bindChart(chartInstance, {
  //       dataFieldsDisplayMode: "splitPanes",
  //       alternateDataFields: true,
  //       putDataFieldsInto: "series",
  //     });
  //     // chartInstance.option("palette", "Soft Pastel");
  //   }
  // }, [pivotGridRef, chartRef]);

  const getData = async (
    fromDate: string,
    toDate: string,
    offset?: boolean
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);
    console.log(fromDate, toDate, offset);
    const filter = {
      limit: 1,
      order: "string",
      where: {
        data_cet: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    console.log(filter);
    try {
      const { data } = await api.get<any>(walletUrlEndpoint, {
        params: { filter },
      });

      console.log(data);
      if (
        typeof data === "object" &&
        data !== null &&
        Object.keys(data).length > 0
      ) {
        setIsLoadingVisible(false);
        return data;
      } else {
        setIsLoadingVisible(false);

        notify(
          notifyMessage + convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getBossaData = async (
    selectedDates: ReturnDateWalletObject
  ): Promise<void> => {
    console.log(selectedDates);

    setSelectedDates({
      fromDate: moment(selectedDates.fromDate).format(displayedDateFormat),
    });
    const data = await getData(selectedDates.fromDate, selectedDates.toDate);
    console.log(data);
    setTransformedData(data as any[]);
  };

  const store = new ArrayStore({
    key: "data_cet",
    data: transformedData.weatherData,
  });

  const onPointHoverChanged = (e: any): void => {
    if (!e.target.isHovered()) {
      e.target.hideTooltip();
    }
  };
  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={false}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Portfel</h2>
      <DateWalletSelector onClick={(dateObj) => getBossaData(dateObj)} />
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <TemperatureChart
            store={store}
            exportFileDataName={createFileNameToExport(
              "Temperatura_OZE",
              selectedDates.fromDate
            )}
            title={"Wykres temperatury farm fotowoltaicznych"}
          />
          <TemperatureChart
            store={store}
            exportFileDataName={"exportFileDataName"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
