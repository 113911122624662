import { DataGrid } from "devextreme-react";
import {
  Scrolling,
  HeaderFilter,
  Column,
  Export,
} from "devextreme-react/data-grid";
import React from "react";
import { onExportingDataGrid } from "../../utils/onExportingCsvFile";

const BpkdDataGrid = React.memo(
  ({
    transformedData,
    offestedDataVisible,
    exportFileDataName,
    variableName,
  }: {
    transformedData: any;
    offestedDataVisible: boolean;
    exportFileDataName: string;
    variableName: string;
  }) => {
    const cellPrepared = (e: any) => {
      if (e.rowType === "data") {
        if (
          e.column.dataField === "Data" ||
          e.column.dataField === "offset_Data_original"
        ) {
          e.cellElement.style.cssText = "background-color: #f5f5f5";
        }
      }
    };
    return (
      <DataGrid
        dataSource={transformedData}
        showBorders={true}
        hoverStateEnabled={true}
        height={600}
        allowColumnResizing={true}
        remoteOperations={false}
        onCellPrepared={cellPrepared}
        rowAlternationEnabled={true}
        onExporting={(e) =>
          onExportingDataGrid(e, `${exportFileDataName}_${variableName}`)
        }
      >
        <Scrolling mode="virtual" />
        {/* <HeaderFilter visible={true} allowSearch={true} /> */}
        <HeaderFilter search={{ enabled: true }} />
        <Export enabled={true} formats={["csv"]} />
        <Column caption="Aktualne" alignment="center">
          <Column
            caption="Data"
            dataField="Doba"
            dataType="date"
            format={"dd/MM/yyyy HH:mm"}
            sortOrder="asc"
            allowFiltering={false}
            allowSorting={true}
            width={150}
          />
          <Column
            caption="MWh"
            dataField={`orginal_${variableName}`}
            dataType="number"
            allowFiltering={true}
            allowSorting={true}
          />
        </Column>
        <Column
          caption="Historyczne"
          alignment="center"
          visible={offestedDataVisible}
        >
          <Column
            caption="Data"
            dataField="offset_Doba_original"
            dataType="date"
            format={"dd/MM/yyyy HH:mm"}
            allowFiltering={false}
            allowSorting={true}
            width={150}
          />
          <Column
            caption="PKD"
            dataField={`offset_${variableName}`}
            dataType="number"
            allowFiltering={true}
            allowSorting={true}
          />
        </Column>
      </DataGrid>
    );
  }
);

export default BpkdDataGrid;
