import moment from "moment";
import React from "react";

const TooltipTemplate = (pointInfo: any) => {
  const countries = [
    { key: "Czechy", name: "Czechy" },
    { key: "Litwa", name: "Litwa" },
    { key: "Niemcy", name: "Niemcy" },
    { key: "Slowacja", name: "Słowacja" },
    { key: "Szwecja", name: "Szwecja" },
    { key: "Ukraina_RZE_CHA", name: "Ukraina RZE-CHA" },
    { key: "Ukraina_ZAM_DOB", name: "Ukraina ZAM-DOB" },
  ];
  const pointData = pointInfo?.point?.data || null;
  console.log(pointInfo?.point?.data);
  const currentDate = pointData?.Data
    ? moment(pointData.Data)
        .set("hour", pointData.Godzina)
        .format("YYYY-MM-DD HH:mm")
    : null;
  const historyDate = pointData?.Data_off
    ? moment(pointData.Data_off)
        .set("hour", pointData.Godzina)
        .format("YYYY-MM-DD HH:mm")
    : null;
  const renderData = (data: any, key: string) =>
    typeof data[key] === "number" ? data[key] : "brak danych";

  return (
    <div className="state-tooltip" style={{ display: "flex", gap: "10px" }}>
      <div>
        <h4 className="title">{currentDate}</h4>
        <p>
          <b>Suma</b>:{pointData.total_sum}
        </p>
        <div className="info-container">
          {countries.map((country: any) => (
            <>
              <p className="singleInfo" key={`${country.key}_EXP`}>
                {country.name} export:{" "}
                <b>{renderData(pointData, `${country.key}_EXP`)}</b>
              </p>
              <p className="singleInfo" key={`${country.key}_IMP`}>
                {country.name} import:{" "}
                <b>{renderData(pointData, `${country.key}_IMP`)}</b>
              </p>
            </>
          ))}
        </div>
      </div>
      {historyDate && (
        <div>
          <h4 className="title">{historyDate}</h4>
          <p>
            <b>Suma</b>:{pointData.total_sum_off}
          </p>
          <div className="info-container">
            {countries.map((country: any) => (
              <>
                <p className="singleInfo" key={`${country.key}_EXP_off`}>
                  {country.name} export:{" "}
                  <b>{renderData(pointData, `${country.key}_EXP_off`)}</b>
                </p>
                <p className="singleInfo" key={`${country.key}_IMP_off`}>
                  {country.name} import:{" "}
                  <b>{renderData(pointData, `${country.key}_IMP_off`)}</b>
                </p>
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipTemplate;
