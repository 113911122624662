import React, { useEffect, useRef, useState, useCallback } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Export,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Label,
  Paging,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "./bossa.css";
import { Chart, LoadPanel, PivotGrid } from "devextreme-react";
import { Size, Legend, Font, Format, Title } from "devextreme-react/bar-gauge";
import notify from "devextreme/ui/notify";
import {
  Grid,
  CommonSeriesSettings,
  Point,
  AdaptiveLayout,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Tooltip,
  ValueAxis,
  ArgumentAxis,
  Series,
} from "devextreme-react/chart";
import TooltipTemplate from "./TooltipTemplate";
import moment from "moment";
import { markerTemplate } from "../../utils/markerTemplates";

const onPointHoverChanged = (e: any): void => {
  if (!e.target.isHovered()) {
    e.target.hideTooltip();
  }
};

const TemperatureChart = (props: any) => {
  const { store, exportFileDataName, title } = props;
  const exportOptions = {
    fileName: `${exportFileDataName}`,
  };

  console.log(store, exportFileDataName, title, "store");
  return (
    <Chart
      // dataSource={transformedData.weatherData}
      dataSource={store}
      palette="Harmony Light"
      onPointHoverChanged={onPointHoverChanged}
      title={title || null}
    >
      <CommonSeriesSettings type={`spline`} argumentField="data_cet">
        <Point visible={false} size={3} symbol="circle"></Point>
      </CommonSeriesSettings>
      <ValueAxis position="right" name="temp">
        <Title text="&#176;C" />
      </ValueAxis>
      {/* <ValueAxis position="right" name="placeID">
      <Title text="MW" />
    </ValueAxis> */}
      <ArgumentAxis
        valueMarginsEnabled={true}
        label={{
          customizeText: function (arg: any) {
            return moment(arg.value).format("DD-MM-YYYY HH:mm");
          },
        }}
        tick={{
          visible: true,
          length: 10,
          shift: 0,
          color: "#000",
          opacity: 1,
          width: 0.1,
        }}
        minorTick={{
          visible: true,
          length: 10,
          shift: 0,
          color: "#000",
          opacity: 1,
          width: 1,
        }}
      >
        <Grid visible={true} />
      </ArgumentAxis>
      <Series
        valueField="placeID1temp"
        name={`Parczew`}
        axis="temp"
        color="#679EC5"
        type={"spline"}
      />
      <Series
        valueField="placeID2temp"
        name={`Długi Kierz`}
        axis="temp"
        color="#E18E92"
        type={"spline"}
      />
      <ZoomAndPan argumentAxis="both" />
      <ScrollBar visible={true} />
      <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
        <Label visible={true}>
          <Font color="#fff" size={12} />
        </Label>
      </Crosshair>
      <Size height={500} />
      <Grid visible={true} />
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="bottom"
        markerRender={markerTemplate}
      />
      <Export enabled={true} {...exportOptions} />
      <AdaptiveLayout width={450} keepLabels={true} />
      <Tooltip
        border={{ color: "#d3d3d3", width: 1 }}
        shadow={{ color: "#888", opacity: 0.5 }}
        interactive={true}
        arrowLength={10}
        enabled={true}
        shared={true}
        cornerRadius={5}
        contentRender={TooltipTemplate}
      >
        <Format type="largeNumber" precision={1} />
      </Tooltip>
    </Chart>
  );
};

export default TemperatureChart;
