import React, { useEffect, useState } from "react";
import "devextreme/data/odata/store";

import { LoadPanel } from "devextreme-react";

import { api } from "../../api/api";

import ResponsiveBox, {
  Row,
  Col,
  Item as ResponsiveItem,
  Location,
} from "devextreme-react/responsive-box";

import { TransformedStockData, ReturnDateObject } from "../../types";

import notify from "devextreme/ui/notify";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import DateSelector from "../../components/date-selector/DateSelector";
import { differenceInDays } from "../../utils/calcDiffBetweenDates";
import moment from "moment";
import { mergeByProperty } from "../../utils/mergeByProperty";
import { listName } from "./bpkdValuesList";
import BpkdChart from "./BpkdChart";
import BpkdDataGrid from "./BpkdDataGrid";
import { notifyMessage } from "../../utils/constants";
const Bpkd = () => {
  const position = { of: "#bpkd-content" };
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [offestedDataVisible, setOffestedDataVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [selectedDates, setSelectedDates] = useState<ReturnDateObject>({
    fromDate: "",
    toDate: "",
    offsetedFromDate: "",
    offsetedToDate: "",
  });
  const bpkdUrlEndpoint = "bpkd";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[]
  >([]);

  const exportOptions = {
    fileName: `${exportFileDataName}_ceny_niezb_rb`,
  };
  const calcDeltaValues = (data: any) => {
    const newData = data?.map((item: any) => {
      listName.forEach((pair) => {
        const [firstKey, newestKey] = pair;
        // console.log(firstKey, newestKey);
        if (item[firstKey] !== undefined && item[newestKey] !== undefined) {
          let deltaKey;
          const firstIndex = firstKey.indexOf("_");
          if (firstKey.startsWith("offset")) {
            deltaKey = `delta_offset_${firstKey.slice(
              firstKey.indexOf("_", firstIndex + 1) + 1
            )}`;
          } else {
            deltaKey = `delta_${firstKey.slice(firstIndex + 1)}`;
          }
          item[deltaKey] = item[firstKey] - item[newestKey];
        }
      });
      // console.log(item);
      return item;
    });
    // console.log(newData);
    return newData;
  };
  const transformBpkdData = (data: any, info: string, daysOffset: number) => {
    const values = [
      "Data",
      "Godzina",
      "Krajowe_zapotrzebowanie_na_moc",
      "Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
      "Generacja_zrodel_wiatrowych",
      "Generacja_zrodel_fotowoltaicznych",
      "Rezerwa_mocy_ponad_zapotrzebowanie",
      "Rezerwa_mocy_ponizej_zapotrzebowania",
    ];
    const transformDateFormat = (date: Date, daysOffset: number) => {
      const newDate = moment(date).add(daysOffset, "days").toString();
      const newDate2 = moment(date).add(daysOffset, "days").format();
      console.log(date, daysOffset, newDate, newDate2, "date 87");
      // .toString();
      // console.log(date, newDate, "date 87");
      return newDate;
    };
    console.log(data, "data 87");
    const newArray = data.map((obj: any) =>
      values.reduce((acc, key) => {
        const newKey = `${key}`;
        if (key === "Data") {
          return {
            ...acc,
            [`${newKey}`]: transformDateFormat(obj[key], daysOffset),
            [`${newKey}_original`]: transformDateFormat(obj[key], 0),
          };
        } else if (key === "Godzina") {
          return { ...acc, [`${newKey}`]: obj[key] };
        } else {
          return { ...acc, [`${info}_${newKey}`]: obj[key] };
        }
      }, {})
    );
    console.log(newArray, "newArray");

    const newArraychangedName = newArray.map((item: any) =>
      daysOffset
        ? Object.fromEntries(
            Object.entries(item).map(([key, value]) =>
              key === "Data" || key === "Godzina"
                ? [key, value]
                : ["offset_" + key, value]
            )
          )
        : item
    );

    return newArraychangedName;
  };

  const getData = async (
    fromDate: string,
    toDate: string,
    daysOffset?: number
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);

    const filter = {
      // limit: 1,
      // order: "string",
      where: {
        data_cet: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      setTransformedData([]);
      const { data } = await api.get<any[]>(bpkdUrlEndpoint, {
        params: { filter },
      });
      console.log(data, "data", fromDate, toDate, "dates");
      if (data?.length) {
        setIsLoadingVisible(false);
        const transformedArray = data.map((obj) => {
          console.log(obj);
          const newest = JSON.parse(JSON.stringify(obj.newest)) || [];
          const first = JSON.parse(JSON.stringify(obj.first)) || [];
          let bpkd_first = [];
          let bpkd_newest = [];
          console.log(first, first.length, newest, newest.length);
          if (first.length) {
            bpkd_first = transformBpkdData(first, "first", daysOffset || 0);
          }
          if (newest.length) {
            bpkd_newest = transformBpkdData(newest, "newest", daysOffset || 0);
          }

          console.log(bpkd_first, bpkd_newest);
          return {
            ...obj,
            newest: bpkd_newest,
            first: bpkd_first,
          };
        });
        console.log(transformedArray);
        const mergedArray = transformedArray.reduce((acc, current) => {
          return acc.concat(
            mergeByProperty(
              current.newest,
              current.first,
              // daysOffset ? "offset_Godzina" : "Godzina"
              "Godzina"
            )
          );
        }, []);
        return mergedArray;
      } else {
        setIsLoadingVisible(false);
        notify(
          notifyMessage + convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getStockData = async (
    selectedDates: ReturnDateObject
  ): Promise<void> => {
    setSelectedDates(selectedDates);
    setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );
    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      const dateDiff = differenceInDays(
        moment(selectedDates.fromDate).toDate(),
        moment(selectedDates.offsetedFromDate).toDate()
      );
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          dateDiff
        ),
      ])
        .then(([data, offsetedData]) => {
          if (data !== undefined && offsetedData !== undefined) {
            setOffestedDataVisible(true);
            const arr = mergeByProperty(data, offsetedData, "Data");
            const newData = calcDeltaValues(arr);
            console.log(newData);
            setTransformedData(newData as any[]);
          }
          if (data !== undefined && offsetedData === undefined) {
            setTransformedData(data as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      console.log(data);
      const newData = calcDeltaValues(data);
      setTransformedData(newData as any[]);
    }
  };

  const onPointHoverChanged = (e: any): void => {
    if (!e.target.isHovered()) {
      e.target.hideTooltip();
    }
  };

  const offsetedNewestSource = [
    // {
    //   value: "offset_newest_Krajowe_zapotrzebowanie_na_moc",
    //   name: "offset_newest_Krajowe_zapotrzebowanie_na_moc",
    // },
    {
      value: "offset_newest_Rezerwa_mocy_ponad_zapotrzebowanie",
      name: "offset_newest_Rezerwa_mocy_ponad_zapotrzebowanie",
    },
    {
      value: "offset_newest_Rezerwa_mocy_ponizej_zapotrzebowania",
      name: "offset_newest_Rezerwa_mocy_ponizej_zapotrzebowania",
    },
    {
      value:
        "offset_newest_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
      name: "offset_newest_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
    },
    {
      value: "offset_newest_Generacja_zrodel_fotowoltaicznych",
      name: "offset_newest_Generacja_zrodel_fotowoltaicznych",
    },
    {
      value: "offset_newest_Generacja_zrodel_wiatrowych",
      name: "offset_newest_Generacja_zrodel_wiatrowych",
    },
  ];
  const offsetedFirstSource = [
    // {
    //   value: "offset_first_Krajowe_zapotrzebowanie_na_moc",
    //   name: "offset_first_Krajowe_zapotrzebowanie_na_moc",
    // },
    {
      value: "offset_first_Rezerwa_mocy_ponad_zapotrzebowanie",
      name: "offset_first_Rezerwa_mocy_ponad_zapotrzebowanie",
    },
    {
      value: "offset_first_Rezerwa_mocy_ponizej_zapotrzebowania",
      name: "offset_first_Rezerwa_mocy_ponizej_zapotrzebowania",
    },
    {
      value:
        "offset_first_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
      name: "offset_first_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
    },
    {
      value: "offset_first_Generacja_zrodel_fotowoltaicznych",
      name: "offset_first_Generacja_zrodel_fotowoltaicznych",
    },
    {
      value: "offset_first_Generacja_zrodel_wiatrowych",
      name: "offset_first_Generacja_zrodel_wiatrowych",
    },
  ];
  const firstSource = [
    // {
    //   value: "first_Krajowe_zapotrzebowanie_na_moc",
    //   name: "first_Krajowe_zapotrzebowanie_na_moc",
    // },
    {
      value: "first_Rezerwa_mocy_ponad_zapotrzebowanie",
      name: "first_Rezerwa_mocy_ponad_zapotrzebowanie",
    },
    {
      value: "first_Rezerwa_mocy_ponizej_zapotrzebowania",
      name: "first_Rezerwa_mocy_ponizej_zapotrzebowania",
    },
    {
      value:
        "first_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
      name: "first_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
    },
    {
      value: "first_Generacja_zrodel_fotowoltaicznych",
      name: "first_Generacja_zrodel_fotowoltaicznych",
    },
    {
      value: "first_Generacja_zrodel_wiatrowych",
      name: "first_Generacja_zrodel_wiatrowych",
    },
  ];
  const newestSources = [
    // {
    //   value: "newest_Krajowe_zapotrzebowanie_na_moc",
    //   name: "newest_Krajowe_zapotrzebowanie_na_moc",
    // },
    {
      value: "newest_Rezerwa_mocy_ponad_zapotrzebowanie",
      name: "newest_Rezerwa_mocy_ponad_zapotrzebowanie",
    },
    {
      value: "newest_Rezerwa_mocy_ponizej_zapotrzebowania",
      name: "newest_Rezerwa_mocy_ponizej_zapotrzebowania",
    },
    {
      value:
        "newest_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
      name: "newest_Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
    },
    {
      value: "newest_Generacja_zrodel_fotowoltaicznych",
      name: "newest_Generacja_zrodel_fotowoltaicznych",
    },
    {
      value: "newest_Generacja_zrodel_wiatrowych",
      name: "newest_Generacja_zrodel_wiatrowych",
    },
  ];
  function screen(width: number) {
    return width < 1400 ? "sm" : "lg";
  }

  const responsiveBoxAttributes = {
    class: "bpkd_reponsive_box",
  };
  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Bieżący Plan Koordynacyjny Dobowy</h2>
      <DateSelector
        defaultOffset={2}
        onClick={(dateObj) => getStockData(dateObj)}
      />
      <div id={"bpkd-content"} className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <ResponsiveBox
            elementAttr={responsiveBoxAttributes}
            singleColumnScreen="sm"
            screenByWidth={screen}
          >
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>

            <Col ratio={1}></Col>
            <Col ratio={1}></Col>

            <ResponsiveItem>
              <Location row={0} col={0} screen="lg"></Location>
              <Location row={0} col={0} screen="sm"></Location>
              <div className="content item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Generacja źródeł wiatrowych"}
                  variableName={"Generacja_zrodel_wiatrowych"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Generacja_zrodel_wiatrowych"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={0} col={1} screen="lg"></Location>
              <Location row={1} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Generacja źródeł fotowoltaicznych"}
                  variableName={"Generacja_zrodel_fotowoltaicznych"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Generacja_zrodel_fotowoltaicznych"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={1} col={0} screen="lg"></Location>
              <Location row={2} col={0} screen="sm"></Location>
              <div className="content item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Rezerwa mocy ponad zapotrzebowania"}
                  variableName={"Rezerwa_mocy_ponad_zapotrzebowanie"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Rezerwa_mocy_ponad_zapotrzebowanie"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={1} col={1} screen="lg"></Location>
              <Location row={3} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Rezerwa mocy poniżej zapotrzebowania"}
                  variableName={"Rezerwa_mocy_ponizej_zapotrzebowania"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Rezerwa_mocy_ponizej_zapotrzebowania"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={2} col={0} screen="lg"></Location>
              <Location row={4} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Krajowe zapotrzebowanie na moc"}
                  variableName={"Krajowe_zapotrzebowanie_na_moc"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Krajowe_zapotrzebowanie_na_moc"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={2} col={1} screen="lg"></Location>
              <Location row={5} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Sumaryczne generacja JG aktywnych"}
                  variableName={
                    "Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa"
                  }
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={
                    "Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa"
                  }
                />
              </div>
            </ResponsiveItem>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Bpkd;
