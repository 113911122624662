import React from "react";

const TooltipTemplate = (pointInfo: any) => {
  console.log(pointInfo);
  //   const date = pointInfo.seriesName.match(/\d{2}-\d{2}-\d{4}/)[0];
  const title = new Date(pointInfo.argumentText).toLocaleString();
  const items: [] = pointInfo.points;
  console.log(items);

  const capitalizFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="state-tooltip">
      <h4 className="title">{title}</h4>
      <div className="info-container">
        {items.map((item: any, index: number) => {
          //   const info = item.replace(/\d{2}-\d{2}-\d{4}/, "").trim();
          return (
            <p className="singleInfo" key={index}>
              {capitalizFirstLetter(item.seriesName)}: {item.value}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default TooltipTemplate;
