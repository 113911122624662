import React, { useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  PatternRule,
  AsyncRule,
  CompareRule,
  EmailRule,
} from "devextreme-react/form";
import { Button as TextBoxButton } from "devextreme-react/text-box";
import LoadIndicator from "devextreme-react/load-indicator";
import { passwordTextRegex } from "../../utils/regexTest";
import notify from "devextreme/ui/notify";
import { ValidationType, validationCallbackType } from "../../types";
import { changePassword } from "../../api/auth";
import {
  TextBox,
  ValidationSummary,
  Validator,
  Button,
} from "devextreme-react";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";

export default function ChangePasswordForm({
  userId,
  userEmail,
}: {
  userId: string | undefined;
  userEmail: string;
}) {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const formData = useRef({
    password: "",
    newPassword: "",
    confirmedPassword: "",
  });
  const [passwordVisibilityMode, setPasswordVisibilityMode] = useState({
    password: "password",
    passwordIcon: "eyeopen",
    newPassword: "password",
    newPasswordIcon: "eyeopen",
    newPasswordConfirmed: "password",
    newPasswordConfirmedIcon: "eyeopen",
  });
  const createEditorOptions = (
    mode: string,
    label: string,
    icon: string,
    password: string,
    passwordIcon: string
  ) => ({
    stylingMode: "filled",
    mode,
    label,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon,
          type: "default",
          onClick: () =>
            setPasswordVisibilityMode((prevState: any) => {
              return {
                ...prevState,
                [password]:
                  prevState[password] === "text" ? "password" : "text",
                [passwordIcon]:
                  prevState[passwordIcon] === "eyeopen"
                    ? "eyeclose"
                    : "eyeopen",
              };
            }),
        },
      },
    ],
  });

  const passwordEditorOptions = createEditorOptions(
    passwordVisibilityMode.password,
    "Hasło",
    passwordVisibilityMode.passwordIcon,
    "password",
    "passwordIcon"
  );

  const newPasswordEditorOptions = createEditorOptions(
    passwordVisibilityMode.newPassword,
    "Nowe hasło",
    passwordVisibilityMode.newPasswordIcon,
    "newPassword",
    "newPasswordIcon"
  );

  const newPasswordConfirmedEditorOptions = createEditorOptions(
    passwordVisibilityMode.newPasswordConfirmed,
    "Potwierdź hasło",
    passwordVisibilityMode.newPasswordConfirmedIcon,
    "newPasswordConfirmed",
    "newPasswordConfirmedIcon"
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    console.log(formData);
    const { password, newPassword } = formData.current;
    if (userId) {
      setLoading(true);
      const result = await changePassword(password, newPassword, userEmail);
      setLoading(false);
      console.log(result);
      if (result.isOk) {
        notify(result.message, "success", 2000);
      } else {
        notify(result.message, "error", 2000);
      }
    }
  };

  const confirmNewPasswordValidation: validationCallbackType = ({
    value,
  }: {
    value: string | number;
  }) => {
    const isDisabled = value === formData.current.password;
    setIsDisabled(isDisabled);
    return !isDisabled;
  };

  const confirmPasswordValidation: validationCallbackType = ({
    value,
  }: {
    value: string | number;
  }) => {
    const isDisabled = value !== formData.current.newPassword;
    setIsDisabled(isDisabled);
    return !isDisabled;
  };

  const passwordLabel = { "aria-label": "Password" };
  return (
    <form onSubmit={onSubmit}>
      <Form
        className="PasswordForm"
        formData={formData.current}
        disabled={loading}
        labelMode="floating"
        labelLocation="top"
        showValidationSummary={true}
      >
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
          label={{ text: passwordEditorOptions.label }}
        >
          <TextBoxButton
            name="password"
            location="after"
            // options={this.passwordButton}
          />
          <RequiredRule message="Hasło jest wymagane" />
          <PatternRule
            pattern={passwordTextRegex}
            message="Hasło nie spełnia wymagań"
          />
          <Label visible={true} location="left" />
        </Item>
        <Item
          dataField={"newPassword"}
          editorType={"dxTextBox"}
          editorOptions={newPasswordEditorOptions}
          label={{ text: newPasswordEditorOptions.label }}
        >
          <RequiredRule message="Hasło jest wymagane" />
          <CustomRule
            message={"Nowe hasło musi być inne od starego"}
            validationCallback={confirmNewPasswordValidation}
          />
          <PatternRule
            pattern={passwordTextRegex}
            message="Hasło nie spełnia wymagań"
          />

          <Label visible={false} />
        </Item>
        <Item
          dataField={"confirmedPassword"}
          editorType={"dxTextBox"}
          editorOptions={newPasswordConfirmedEditorOptions}
          label={{ text: newPasswordConfirmedEditorOptions.label }}
        >
          <RequiredRule message="Hasło jest wymagne" />

          <PatternRule
            pattern={passwordTextRegex}
            message="Hasło nie spełnia wymagań"
          />

          <CustomRule
            message={"Hasła nie pasują"}
            validationCallback={confirmPasswordValidation}
          />
          <Label visible={false} />
        </Item>
        <ValidationSummary id="summary" />
        <ButtonItem
          horizontalAlignment={"left"}
          itemType="button"
          buttonOptions={{
            disabled: loading,
            useSubmitBehavior: true,
            text: "Zmień hasło",
            type: "success",
          }}
        />
      </Form>
    </form>
  );
}
