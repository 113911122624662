export const mergeByProperty = (
  target: Array<any>,
  source: Array<any>,
  prop: string
): Array<any> => {
  let result = [...target];

  source.forEach((sourceElement) => {
    let targetElement = result.find((targetElement) => {
      return sourceElement[prop] === targetElement[prop];
    });

    if (targetElement) {
      Object.assign(targetElement, sourceElement);
    } else {
      result.push(sourceElement);
    }
  });
  // console.log(result);
  return result;
};
