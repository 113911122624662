import React, { useEffect, useRef, useState, useCallback } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Export,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";

import { Chart, LoadPanel } from "devextreme-react";
import { Label } from "devextreme-react/form";
import { api } from "../../api/api";
import { Font, Legend, Size, Title } from "devextreme-react/bar-gauge";
import {
  AdaptiveLayout,
  CommonSeriesSettings,
  Grid,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Series,
  ValueAxis,
  Point,
  Tooltip,
  Format,
  ArgumentAxis,
} from "devextreme-react/chart";

import {
  TransformedStockData,
  PseNiezbRbFromToDate,
  PseNiezbRbInterface,
} from "../../types";

import {
  formatDateRange,
  transformDate,
  transformDateToShortFormat,
} from "../../utils/transformDate";
import { onExportingDataGrid } from "../../utils/onExportingCsvFile";
import { setSelectedDates as setToday } from "../../utils/DateSelectors";
import notify from "devextreme/ui/notify";
import { subtractOffsetFromDate } from "../../utils/offsetDate";
import { createFileNameToExport } from "../../utils/createFileNameToExportCsv";
import DateOneDaySelector from "../../components/date-oneDay-selector/DateOneDaySelector";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import TooltipTemplate from "./TooltipTemplate";
import { markerTemplate } from "../../utils/markerTemplates";
import { notifyMessage } from "../../utils/constants";
const PseNiezbRb = () => {
  const position = { of: "#chart" };
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [offestedDataVisible, setOffestedDataVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const stockUrlEndpoint = "ceny-niezb-rb";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[]
  >([]);
  const [selectedDates, setSelectedDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });

  const changeOffsetedObjArrnames = (arr: Array<any>) => {
    const newArray = arr.map((obj) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, val]) =>
          key === "Godzina" ? [key, val] : [`${key}_off`, val]
        )
      )
    );
    return newArray;
  };
  const exportOptions = {
    fileName: `${exportFileDataName}_cro_i_niezbilansowanie`,
  };
  const getData = async (
    fromDate: string,
    toDate: string,
    offset?: boolean
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);

    const filter = {
      limit: 1,
      order: "string",
      where: {
        data_cet: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      setTransformedData([]);
      const { data } = await api.get<PseNiezbRbInterface[]>(stockUrlEndpoint, {
        params: { filter },
      });
      console.log(data);
      if (data?.length) {
        setIsLoadingVisible(false);
        console.log(offset, !offset, !!offset);
        return !!offset
          ? changeOffsetedObjArrnames(data[0].dane)
          : data[0].dane;
      } else {
        setIsLoadingVisible(false);
        notify(
          notifyMessage + convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getStockData = async (
    selectedDates: PseNiezbRbFromToDate
  ): Promise<void> => {
    console.log(selectedDates);
    setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );

    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      setSelectedDates({
        fromDate: convertLongDateStringToShortFormat(selectedDates.fromDate),
        toDate: convertLongDateStringToShortFormat(
          selectedDates.offsetedFromDate
        ),
      });
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          true
        ),
      ])
        .then(([data, offsetedData]) => {
          console.log(data, offsetedData);
          if (data !== undefined && offsetedData !== undefined) {
            setOffestedDataVisible(true);
            const concatedArr = [...data, ...offsetedData].reduce(
              (acc, curr) => {
                const findingIndex = acc.findIndex(
                  (el: { Godzina: any }) => el.Godzina === curr.Godzina
                );
                if (findingIndex !== -1) {
                  acc[findingIndex] = {
                    ...acc[findingIndex],
                    ...curr,
                  };
                } else {
                  acc.push({ ...curr });
                }
                return acc;
              },
              []
            );
            setTransformedData(concatedArr as any[]);
          } else if (data !== undefined && offsetedData === undefined) {
            setTransformedData(data as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedDates({
        fromDate: convertLongDateStringToShortFormat(selectedDates.fromDate),
        toDate: "",
      });
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      console.log(data);
      setTransformedData(data as any[]);
    }
  };

  useEffect(() => {
    const data = setToday({
      selectedFromDate: new Date(),
    });
    getStockData(data as PseNiezbRbFromToDate);
  }, []);

  const onPointHoverChanged = (e: any): void => {
    if (!e.target.isHovered()) {
      e.target.hideTooltip();
    }
  };

  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>CRO oraz niezbilansowanie</h2>
      <DateOneDaySelector onClick={(dateObj) => getStockData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Chart
            dataSource={transformedData}
            palette="Harmony Light"
            onPointHoverChanged={onPointHoverChanged}
          >
            <CommonSeriesSettings type={`spline`} argumentField="Godzina">
              <Point visible={false} size={3} symbol="circle"></Point>
            </CommonSeriesSettings>
            <ValueAxis position="left" name="cena">
              <Title text="zł/MWh" />
            </ValueAxis>
            <ValueAxis position="right" name="niezbilansowanie">
              <Title text="MW" />
            </ValueAxis>
            <ArgumentAxis
              valueMarginsEnabled={true}
              label={{
                customizeText: function (arg: any) {
                  console.log(arg);
                  return String(arg.value).padStart(2, "0") + ":00";
                },
              }}
              tick={{
                visible: true,
                length: 6,
                shift: 0,
                color: "#000",
                opacity: 1,
                width: 1,
              }}
              minorTick={{
                visible: true,
                length: 3,
                shift: 0,
                color: "#000",
                opacity: 1,
                width: 1,
              }}
            >
              <Grid visible={true} />
            </ArgumentAxis>
            {!!offestedDataVisible && (
              <Series
                valueField="CRO_off"
                name={`CRO-${selectedDates.toDate}`}
                axis="cena"
                color="#9ACD32"
                type={"bar"}
                visible={offestedDataVisible}
              />
            )}
            {!!offestedDataVisible && (
              <Series
                valueField="CROs_off"
                name={`CROs-${selectedDates.toDate}`}
                axis="cena"
                color="#7ba428"
                type={"bar"}
                visible={offestedDataVisible}
              />
            )}
            {!!offestedDataVisible && (
              <Series
                valueField="CROz_off"
                name={`CROz-${selectedDates.toDate}`}
                axis="cena"
                color="#628320"
                type={"bar"}
                visible={offestedDataVisible}
              />
            )}
            <Series
              valueField="CRO"
              name={`CRO-${selectedDates.fromDate}`}
              axis="cena"
              color="#FACC00"
              type={"bar"}
            />
            <Series
              valueField="CROs"
              name={`CROs-${selectedDates.fromDate}`}
              axis="cena"
              color="#c8a300"
              type={"bar"}
            />

            <Series
              valueField="CROz"
              name={`CROz-${selectedDates.fromDate}`}
              axis="cena"
              color="#a08200"
              type={"bar"}
            />
            {!!offestedDataVisible && (
              <Series
                valueField="Niezbilansowanie_off"
                name={`Niezbilansowanie-${selectedDates.toDate}`}
                axis="niezbilansowanie"
                color="#0ecfe3"
              />
            )}
            {!!offestedDataVisible && (
              <Series
                valueField="Stan_zakontraktowania_off"
                name={`Stan zakontraktowania-${selectedDates.toDate}`}
                axis="niezbilansowanie"
                color="#09909e"
              />
            )}
            <Series
              valueField="Niezbilansowanie"
              name={`Niezbilansowanie-${selectedDates.fromDate}`}
              axis="niezbilansowanie"
              color="#eca419"
            />
            <Series
              valueField="Stan_zakontraktowania"
              name={`Stan zakontraktowania-${selectedDates.fromDate}`}
              axis="niezbilansowanie"
              color="#a8730d"
            />
            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <Size height={500} />
            <Grid visible={true} />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
              markerRender={markerTemplate}
            />
            <Export enabled={true} {...exportOptions} />
            <AdaptiveLayout width={450} keepLabels={true} />
            <Tooltip
              border={{ color: "#d3d3d3", width: 1 }}
              shadow={{ color: "#888", opacity: 0.5 }}
              interactive={true}
              arrowLength={10}
              enabled={true}
              shared={true}
              cornerRadius={5}
              contentRender={TooltipTemplate}
            >
              <Format type="largeNumber" precision={1} />
            </Tooltip>
          </Chart>

          <DataGrid
            dataSource={transformedData}
            showBorders={true}
            hoverStateEnabled={true}
            height={800}
            remoteOperations={true}
            onCellPrepared={(e) => console.log(e)}
            onExporting={(e) =>
              onExportingDataGrid(
                e,
                `${exportFileDataName}_cro_i_niezbilansowanie`
              )
            }
          >
            <HeaderFilter visible={true} allowSearch={true} />
            <Scrolling mode="virtual" />
            <Export enabled={true} formats={["csv"]} />
            <Column
              dataField="Godzina"
              dataType="string"
              allowFiltering={true}
              allowSorting={true}
              width={90}
              customizeText={(e) => String(e.value).padStart(2, "0") + ":00"}
            />
            <Column caption="CRO [zł/MWh]">
              <Column
                caption={selectedDates.fromDate}
                dataField="CRO"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption={selectedDates.toDate}
                dataField="CRO_off"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
                visible={offestedDataVisible}
              />
            </Column>
            <Column caption="CROs [zł/MWh]">
              <Column
                caption={selectedDates.fromDate}
                dataField="CROs"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption={selectedDates.toDate}
                dataField="CROs_off"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
                visible={offestedDataVisible}
              />
            </Column>
            <Column caption="CROz [zł/MWh]">
              <Column
                caption={selectedDates.fromDate}
                dataField="CROz"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption={selectedDates.toDate}
                dataField="CROz_off"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
                visible={offestedDataVisible}
              />
            </Column>
            <Column caption="Niezbilansowanie [MW]">
              <Column
                caption={selectedDates.fromDate}
                dataField="Niezbilansowanie"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption={selectedDates.toDate}
                dataField="Niezbilansowanie_off"
                dataType="string"
                allowFiltering={true}
                allowSorting={true}
                visible={offestedDataVisible}
              />
            </Column>
            <Column caption="Stan zakontraktowania [MW]">
              <Column
                caption={selectedDates.fromDate}
                dataField="Stan_zakontraktowania"
                dataType="string"
              />
              <Column
                caption={selectedDates.toDate}
                dataField="Stan_zakontraktowania_off"
                dataType="string"
                visible={offestedDataVisible}
              />
            </Column>
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PseNiezbRb;
