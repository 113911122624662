import React from "react";

const TooltipTemplate = (pointInfo: any) => {
  const data = pointInfo.point.data;

  type InfoItemProps = {
    label: string;
    value: string | null;
  };
  const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => {
    return value ? (
      <p className="singleInfo">
        <strong>{label}</strong>: {value}
      </p>
    ) : null;
  };
  return (
    <div className="state-tooltip">
      <h4 className="title">{"Aktualne"}</h4>
      <div className="info-container">
        <InfoItem label="Cena kupna (PLN)" value={data.cenaKupna} />
        <InfoItem label="skum. wol kupna (MWh)" value={data.skumWolKupna} />
        <InfoItem label="Cena Sprzedaży (PLN)" value={data.cenaSprzedazy} />
        <InfoItem
          label="Skum. wol sprzedaży (MWh)"
          value={data.skumWolSprzedazy}
        />
      </div>
      {data["cenaKupna-offseted"] ||
      data["skumWolKupna-offseted"] ||
      data["cenaSprzedazy-offseted"] ||
      data["skumWolSprzedazy-offseted"] ? (
        <>
          <h4 className="title">{"Historyczne"}</h4>
          <div className="info-container">
            <InfoItem label="Cena kupna" value={data["cenaKupna-offseted"]} />
            <InfoItem
              label="skum. wol kupna"
              value={data["skumWolKupna-offseted"]}
            />
            <InfoItem
              label="Cena Sprzedaży"
              value={data["cenaSprzedazy-offseted"]}
            />
            <InfoItem
              label="Skum. wol sprzedaży"
              value={data["skumWolSprzedazy-offseted"]}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TooltipTemplate;
